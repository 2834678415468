import React from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useNavigate } from "@reach/router";

import { ExchangeData } from "./data";

const Exchange = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <h3 className="text-center">Exchange</h3>
      <Table className="text-center" responsive striped bordered hover>
        <thead>
          <tr>
            <th>No</th>
            <th>Customer Name</th>
            <th>Contact No</th>
            <th>Model Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {ExchangeData.map((exchange, index) => (
            <tr
              onClick={() => navigate(`/exchange/${exchange.id}`)}
              key={exchange.id}
            >
              <td>{index + 1}</td>
              <td>{exchange.customer_name}</td>
              <td>{exchange.ph_no}</td>
              <td>{exchange.model_name}</td>
              <th>
                <Button variant="outline-primary" size="sm">
                  View more
                </Button>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Exchange;
