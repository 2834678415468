import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { selectUserData } from "../login/loginSlice";
import { useSelector } from "react-redux";
import styles from "./DashBoard.module.css";
import { Link } from "@reach/router";

const DashBoard = () => {
  const userData = useSelector(selectUserData);
  const SERVER_HOST = process.env.REACT_APP_API_BASE_ENDPOINT;

  return (
    <Container className={styles.container}>
      <div className={styles.welcomeContainer}>
        <h1 className={styles.margin}>Welcome</h1>
        {userData && (
          <Row className={styles.brandContainer}>
            <Col md="5">
              <h2 className={styles.margin}>{userData?.dealer?.name}</h2>
            </Col>
            <Col md="7" className={styles.rightFlex}>
              <div className={styles.imgWrapper}>
                <img
                  src={`${SERVER_HOST}${userData.dealer.logo}`}
                  alt="logo"
                  className={styles.img}
                />
              </div>
            </Col>
          </Row>
        )}
      </div>
      <div className={styles.linkContiner}>
        {userData.user_type === "showroom_admin" && (
          <>
            <Link to="/leads" className={`${styles.link} `}>
              Leads
            </Link>
            <Link to="/exchange" className={`${styles.link} `}>
              Exchange
            </Link>
            <Link to="/testdrives" className={`${styles.link} `}>
              Test Drives
            </Link>
            <Link to="/booking" className={`${styles.link}`}>
              Bookings
            </Link>
          </>
        )}
        {userData.user_type === "dealer_admin" && (
          <>
            <Link to="/models" className={`${styles.link} `}>
              Models
            </Link>
            <Link to="/offers" className={`${styles.link} `}>
              Offers
            </Link>
            <Link to="/showroom" className={`${styles.link} `}>
              Showrooms
            </Link>
          </>
        )}
      </div>
    </Container>
  );
};

export default DashBoard;
