import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectoffer,
  fetchoffer,
  selectIsLoading,
  setLoadingStatus,
  selectIsUpateAPILoading,
  updateofferDetails,
  deleteofferDetails,
  updateBulkoffer,
  selectIsBulkUpateAPILoading,
} from "./offerSlice";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import styles from "./offer.module.css";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "@reach/router";
import moment from "moment";
import { filterForOffers } from "../models/Utils";
import { TransmissionType, FuelType } from "../data";

const OfferDetails = ({ offerId = "" }) => {
  const offer = useSelector(selectoffer);
  const isLoading = useSelector(selectIsLoading);
  const isUpateAPILoading = useSelector(selectIsUpateAPILoading);
  const isBulkUpateAPILoading = useSelector(selectIsBulkUpateAPILoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [offerDetails, setofferDetails] = useState({});
  const [editForm, setEditForm] = useState(false);
  const [editBulkForm, setEditBulkForm] = useState(false);
  const [submitButton, setSubmitButton] = useState(false);
  const [selectedBulk, setSelectedBulk] = useState([]);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchoffer(offerId));
  }, [dispatch, offerId]);

  useEffect(() => {
    if (offer) {
      setofferDetails(offer);
      setEndDate(new Date(offer.end_date));
      setStartDate(new Date(offer.start_date));
    }
  }, [offer]);
  const minor_color = `mb-2 ${styles.text}`;

  const onChange = (value, offerContent) => {
    setofferDetails({
      ...offerDetails,
      [offerContent]: value,
    });
  };

  const handleClick = () => {
    if (editForm) {
      const payload = {
        name: offerDetails.name,
        description: offerDetails.description,
        start_date: offerDetails.start_date,
        end_date: offerDetails.end_date,
        amount: offerDetails.amount,
        valuation: offerDetails.valuation,
        is_active: offerDetails.is_active,
        is_additional: offerDetails.is_additional,
        is_optional: offerDetails.is_optional,
        applicable_id: offerDetails.applicable_id,
        applicable_type: offerDetails.applicable_type,
      };
      dispatch(updateofferDetails(offerDetails.id, payload));
      setEditForm(false);
    } else {
      setEditForm(true);
    }
  };

  const bulkEditHandler = () => {
    if (editBulkForm) {
      dispatch(updateBulkoffer(offerDetails.id, { data: data }));
      setEditBulkForm(false);
    } else {
      setEditBulkForm(true);
    }
  };

  const cancelBulkEditHandler = () => {
    setEditBulkForm(false);
    dispatch(fetchoffer(offerId));
  };
  const cancelHandler = () => {
    setEditForm(false);
    dispatch(fetchoffer(offerId));
  };

  const deleteHandler = (id) => {
    dispatch(deleteofferDetails(id, navigate));
  };

  const startDateHandler = (date) => {
    setStartDate(date);
    date = moment(date).format("yyyy-MM-DD");
    setofferDetails({ ...offerDetails, start_date: date });
  };

  const endDateHandler = (date) => {
    setEndDate(date);
    date = moment(date).format("yyyy-MM-DD");
    setofferDetails({ ...offerDetails, end_date: date });
  };

  const [Fuel, setFuel] = useState("");
  const [Type, setType] = useState("");

  const [filteredVariants, setFilteredVariants] = useState("");
  const [transmission_type, setTransmission_type] = useState("");
  const [fuel_type, setFuel_type] = useState("");

  const TypeHandler = (datasource, SetDataSource, type) => {
    if (datasource.indexOf(type) !== -1) {
      SetDataSource(datasource.filter((value) => value !== type));
    } else {
      SetDataSource((brandId) => [...brandId, type]);
    }
  };

  const filterBulkUpdate = (value) => {
    if (value >= 0) {
      let newValues = filteredVariants.map((item) => ({
        value: value,
        id: item.id,
      }));
      setSubmitButton(true);
      setSelectedBulk(newValues);
    } else {
      setSubmitButton(false);
    }
  };

  const selectedBulkUpdate = () => {
    dispatch(updateBulkoffer(offerDetails.id, { data: selectedBulk }));
  };

  useEffect(() => {
    // console.log(fuel_type, transmission_type);
    if (offer.variant_offers) {
      setFuel([
        ...new Set(
          offer.variant_offers.map((variant) => variant["variant"]["fuel_type"])
        ),
      ]);
      setType([
        ...new Set(
          offer.variant_offers.map(
            (variant) => variant["variant"]["transmission_type"]
          )
        ),
      ]);
      const filteredVariants = filterForOffers(offer.variant_offers, {
        transmission_type,
        fuel_type,
      });
      setFilteredVariants(filteredVariants);
    }
  }, [offer, fuel_type, transmission_type]);

  return (
    <Container>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        offerDetails &&
        offerDetails.id && (
          <div className={styles.offerBox}>
            <h3 className="text-center">{offer.name} </h3>
            <br />
            <div className={styles.detailContainer}>
              <Form>
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Name :
                  </Form.Label>
                  <Col sm="9">
                    <FormControl
                      className="font-weight-bold"
                      edit={editForm}
                      onChange={onChange}
                      value={offerDetails.name}
                      offerContent="name"
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Description :
                  </Form.Label>
                  <Col sm="9">
                    <FormControl
                      as="textarea"
                      className="font-weight-bold"
                      edit={editForm}
                      onChange={onChange}
                      value={offerDetails.description}
                      offerContent="description"
                    />
                  </Col>
                </Form.Group>

                {!editForm ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Start Date
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        value={offerDetails.start_date}
                        className="font-weight-bold"
                      />
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Start Date :
                    </Form.Label>
                    <Col sm="9">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => startDateHandler(date)}
                        onFocus={(e) => e.target.blur()}
                        minDate={new Date()}
                        dateFormat="yyyy-MM-d"
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </Col>
                  </Form.Group>
                )}

                {!editForm ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      End Date
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        value={offerDetails.end_date}
                        className="font-weight-bold"
                      />
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      End Date :
                    </Form.Label>
                    <Col sm="9">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => endDateHandler(date)}
                        onFocus={(e) => e.target.blur()}
                        dateFormat="yyyy-MM-d"
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                      />
                    </Col>
                  </Form.Group>
                )}

                {!editForm ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Is Active
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        value={offerDetails.is_active ? "Yes" : "No"}
                        className="font-weight-bold"
                      />
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3"></Form.Label>
                    <Col sm="9">
                      <Form.Check
                        label="Is Active"
                        id="disabled-custom-switch"
                        checked={offerDetails.is_active}
                        onChange={(e) =>
                          setofferDetails({
                            ...offerDetails,
                            is_active: e.target.checked,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                )}

                {!editForm ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Is Optional
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        value={offerDetails.is_optional ? "Yes" : "No"}
                        className="font-weight-bold"
                      />
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3"></Form.Label>
                    <Col sm="9">
                      <Form.Check
                        label="Is Optional"
                        id="disabled-custom-switch"
                        checked={offerDetails.is_optional}
                        onChange={(e) =>
                          setofferDetails({
                            ...offerDetails,
                            is_optional: e.target.checked,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                )}

                {!editForm ? (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Is Additional
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        value={offerDetails.is_additional ? "Yes" : "No"}
                        className="font-weight-bold"
                      />
                    </Col>
                  </Form.Group>
                ) : (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3"></Form.Label>
                    <Col sm="9">
                      <Form.Check
                        label="Is Additional"
                        id="disabled-custom-switch"
                        checked={offerDetails.is_additional}
                        onChange={(e) =>
                          setofferDetails({
                            ...offerDetails,
                            is_additional: e.target.checked,
                          })
                        }
                      />
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Amount :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      className="font-weight-bold"
                      plaintext={!editForm}
                      readOnly={!editForm}
                      onChange={(e) =>
                        setofferDetails({
                          ...offerDetails,
                          amount: parseFloat(e.target.value),
                        })
                      }
                      value={offerDetails.amount || ""}
                    />
                  </Col>
                </Form.Group>

                {/* {!editForm ? ( */}
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Valuation
                  </Form.Label>
                  <Col sm="9">
                    <FormControl
                      value={offerDetails.valuation}
                      className="font-weight-bold"
                    />
                  </Col>
                </Form.Group>
                {/* ) : (
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Valuation :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="select"
                        value={offerDetails.valuation}
                        onChange={(e) =>
                          setofferDetails({
                            ...offerDetails,
                            valuation: e.target.value,
                          })
                        }
                      >
                        <option disabled value="">
                          Select Valuation
                        </option>
                        <option value="flat">Flat</option>
                        <option value="percentage">Percentage</option>
                      </Form.Control>
                    </Col>
                  </Form.Group>
                )} */}

                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Offer Type :
                  </Form.Label>
                  <Col sm="9">
                    <FormControl
                      className="font-weight-bold"
                      value={offerDetails.offer_type.name}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Applicable Type :
                  </Form.Label>
                  <Col sm="9">
                    <FormControl
                      className="font-weight-bold"
                      value={offerDetails.applicable_type}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Applicable Name :
                  </Form.Label>
                  <Col sm="9">
                    <FormControl
                      className="font-weight-bold"
                      value={offerDetails.applicable.name}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                // className={styles.buttons}
                >
                  <Button
                    variant="success"
                    size="sm"
                    onClick={handleClick}
                    disabled={isUpateAPILoading}
                    // disabled={isLoading}
                  >
                    {isUpateAPILoading ? (
                      // {isLoading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : null}{" "}
                    {editForm ? "Save changes" : "Edit Values"}
                  </Button>{" "}
                  {editForm ? (
                    <Button variant="default" size="sm" onClick={cancelHandler}>
                      Cancel
                    </Button>
                  ) : null}
                  {!editForm ? (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => deleteHandler(offerDetails.id)}
                      disabled={isUpateAPILoading}
                      // disabled={isLoading}
                    >
                      Delete
                    </Button>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Form>
            </div>

            <h3 className="text-center">Offer Type </h3>
            {offer.offer_type && (
              <div className={styles.detailContainer}>
                <p className={minor_color}>
                  Name :
                  <span className={styles.textData}>
                    {offer.offer_type.name}
                  </span>
                </p>
                <p className={minor_color}>
                  Condition :
                  <span className={styles.textData}>
                    {offer.offer_type.condition}
                  </span>
                </p>
                <p className={minor_color}>
                  Description :
                  <span className={styles.textData}>
                    {offer.offer_type.description}
                  </span>
                </p>
              </div>
            )}

            <div className={styles.variantFilter}>
              {Type && (
                <>
                  {Type.map((type) => (
                    <div
                      key={type}
                      className={
                        transmission_type.includes(type)
                          ? `${styles.selected} ${styles.bodyType} `
                          : `${styles.bodyType}`
                      }
                      onClick={() =>
                        TypeHandler(
                          transmission_type,
                          setTransmission_type,
                          type
                        )
                      }
                    >
                      <p className={styles.bodyname}>
                        {TransmissionType[type]}
                      </p>
                    </div>
                  ))}
                </>
              )}{" "}
              {Fuel && (
                <>
                  {Fuel.map((type) => (
                    <div
                      key={type}
                      className={
                        fuel_type.includes(type)
                          ? `${styles.selected} ${styles.bodyType} `
                          : `${styles.bodyType}`
                      }
                      onClick={() => TypeHandler(fuel_type, setFuel_type, type)}
                    >
                      <p className={styles.bodyname}>{FuelType[type]}</p>
                    </div>
                  ))}
                </>
              )}
            </div>
            {!!fuel_type.length || !!transmission_type.length ? (
              <Form.Group as={Row}>
                <Form.Label className={minor_color} column sm="3">
                  Set Value :
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    className="font-weight-bold"
                    onChange={(e) => {
                      let newValue = parseFloat(e.target.value);
                      if (!isNaN(newValue) && newValue >= 0) {
                        filterBulkUpdate(newValue);
                      }
                    }}
                  />
                </Col>
                {submitButton && (
                  <Button onClick={selectedBulkUpdate}>Submit</Button>
                )}
              </Form.Group>
            ) : (
              ""
            )}
            <div>
              <div className={styles.variantContainer}>
                {filteredVariants &&
                  filteredVariants
                    .sort(
                      (a, b) =>
                        a?.variant?.ex_showroom_starting -
                        b?.variant?.ex_showroom_starting
                    )
                    .map((obj) => (
                      <div key={obj.id} className={styles.variantBox}>
                        <p className={minor_color}>
                          Offer Type : <span>{offer.offer_type.name}</span>
                        </p>
                        {!editBulkForm ? (
                          <p className={minor_color}>
                            Value : <span>{obj.value}</span>
                          </p>
                        ) : (
                          <Form.Group as={Row}>
                            <Form.Label className={minor_color} column sm="3">
                              Value
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                className="font-weight-bold"
                                plaintext={!editBulkForm}
                                readOnly={!editBulkForm}
                                onChange={(e) => {
                                  let newValue = parseFloat(e.target.value);
                                  if (isNaN(newValue)) {
                                    newValue = 0;
                                  }
                                  // Update filteredVariants
                                  setFilteredVariants((prevState) =>
                                    prevState.map((item, i) =>
                                      item.id === obj.id
                                        ? { ...item, value: newValue }
                                        : item
                                    )
                                  );
                                  // Update data
                                  setData((prevData) => {
                                    const newData = [...prevData];
                                    const variantIndex = newData.findIndex(
                                      (item) => item.id === obj.id
                                    );
                                    if (variantIndex !== -1) {
                                      newData[variantIndex] = {
                                        ...newData[variantIndex],
                                        value: newValue,
                                      };
                                    } else {
                                      newData.push({
                                        id: obj.id,
                                        value: newValue,
                                      });
                                    }
                                    return newData;
                                  });
                                }}
                                value={obj.value || ""}
                              />
                            </Col>
                          </Form.Group>
                        )}
                        <p className={minor_color}>
                          Variant : <span>{obj.variant.name}</span>
                        </p>
                        <p className={minor_color}>
                          Ex Showroom Starting :{" "}
                          <span className="font-weight-bold">
                            {obj.variant.ex_showroom_starting}
                          </span>
                        </p>
                        <p className={minor_color}>
                          Offer Amount :{" "}
                          <span className="font-weight-bold">
                            {obj.calculated_amount}
                          </span>
                        </p>
                        <div className="text-center my-3">
                          <Link
                            className={styles.offerLink}
                            to={`variant-offers/${obj.id}`}
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    ))}
              </div>
              <div className="text-center my-5">
                <Button
                  variant="success"
                  size="sm"
                  onClick={bulkEditHandler}
                  disabled={isBulkUpateAPILoading}
                >
                  {isBulkUpateAPILoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : null}{" "}
                  {editBulkForm ? "Save changes" : "Edit Bulk Values"}
                </Button>{" "}
                {editBulkForm ? (
                  <Button
                    variant="default"
                    size="sm"
                    onClick={cancelBulkEditHandler}
                  >
                    Cancel
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        )
      )}
    </Container>
  );
};

export default OfferDetails;

const FormControl = ({ edit, value, offerContent, onChange, as }) => {
  return (
    <Form.Control
      as={as}
      className="font-weight-bold"
      plaintext={!edit}
      readOnly={!edit}
      value={value || ""}
      onChange={(e) => onChange(e.target.value, offerContent)}
    />
  );
};
