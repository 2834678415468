import { createSlice } from "@reduxjs/toolkit";
import { getLead, getLeads, putLead } from "../../api/leads";

export const slice = createSlice({
  name: "lead",
  initialState: {
    leads: [],
    lead: {},
    loadingleads: true,
    loadinglead: true,
    leadserror: {
      message: "",
    },
    leaderror: {
      message: "",
    },
  },
  reducers: {
    getleadsSuccess: (state, action) => {
      state.leads = action.payload;
      state.loadingleads = false;
      state.leadserror = {};
    },
    getleadsFailed: (state) => {
      state.leads = [];
      state.loadingleads = false;
      state.leadserror.message = "Something went wrong";
    },

    clearleads: (state) => {
      state.leads = [];
      state.loadingleads = true;
      state.leadserror = {
        message: "",
      };
    },
    getleadSuccess: (state, action) => {
      state.lead = action.payload;
      state.loadinglead = false;
      state.leadserror = {};
    },
    getleadFailed: (state) => {
      state.lead = {};
      state.loadinglead = false;
      state.leaderror.message = "Something went wrong";
    },

    clearlead: (state) => {
      state.leads = {};
      state.loadinglead = true;
      state.leaderror = {
        message: "",
      };
    },
  },
});

export const selectleads = (state) => state.lead.leads || [];
export const selectIsLeadsLoading = (state) => state.lead.loadingleads;
export const selectlead = (state) => state.lead.lead || {};
export const selectIsLeadLoading = (state) => state.lead.loadinglead;

export const {
  getleadsSuccess,
  getleadsFailed,
  clearleads,
  getleadSuccess,
  getleadFailed,
  clearlead,
} = slice.actions;
export const fetchleads = () => async (dispatch) => {
  try {
    const leadsDetails = await getLeads();
    dispatch(getleadsSuccess(leadsDetails.data));
  } catch (err) {
    dispatch(getleadsFailed(err.toString()));
  }
};
export const fetchlead = (id) => async (dispatch) => {
  try {
    const leadDetails = await getLead(id);
    dispatch(getleadSuccess(leadDetails.data));
  } catch (err) {
    dispatch(getleadFailed(err.toString()));
  }
};

export const updateLead = (id, data) => async (dispatch) => {
  try {
    const leadDetails = await putLead(id, data);
    dispatch(getleadSuccess(leadDetails.data));
  } catch (err) {
    dispatch(getleadFailed(err.toString()));
  }
};

export default slice.reducer;
