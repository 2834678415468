import { getApi, putApi } from "./utils";

export const getLead = (id) => {
  return getApi(`leads/${id}`);
};

export const getLeads = () => {
  return getApi("leads", { per_page: 1000 });
};

export const putLead = (id, data) => {
  return putApi(`leads/${id}`, data);
};
