import { deleteApi, getApi, postApi, putApi } from "./utils";

export const getOfferTypes = () => {
  return getApi("offer_types");
};

export const postOfferTypes = (data) => {
  return postApi(`offer_types`, data);
};

export const getOfferType = (id) => {
  return getApi(`offer_types/${id}`);
};

export const putOfferType = (id, data) => {
  return putApi(`offer_types/${id}`, data);
};

export const deleteOfferType = (id) => {
  return deleteApi(`offer_types/${id}`);
};
