import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTestDrives,
  selectIsLoading,
  selectTestDrives,
} from "./testDriveSlice";
import Container from "react-bootstrap/Container";
import { useNavigate } from "@reach/router";

import styles from "../home/Home.module.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { selectUserData } from "../login/loginSlice";
import moment from "moment/moment";

import tdstyles from "./styles.module.css";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TestDrive = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedModel, setselectedModel] = useState("");
  const [models, setModels] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const testdrives = useSelector(selectTestDrives);
  const userData = useSelector(selectUserData);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  if (!userData.token) {
    navigate("/login");
  }
  useEffect(() => {
    dispatch(fetchTestDrives());
  }, [dispatch]);

  useEffect(() => {
    if (testdrives.length > 0) {
      setModels([
        ...new Set(testdrives.map((testdrive) => testdrive.model.name)),
      ]);

      const filteredVariants = filterData(
        testdrives,
        selectedModel,
        startDate,
        endDate
      );
      setFilteredData(filteredVariants);
    }
  }, [testdrives, selectedModel, startDate, endDate]);

  const exportToCSV = (apiData, fileName) => {
    const filteredData = apiData.map((item) => ({
      Showroom: item.showroom.place,
      Model: item.model.name,
      BookingDate: `${moment(item.created_at).format("DD-MMMM-YYYY")}`,
      Customer: item.customer.first_name,
      ContactNo: item.customer.phone,
      Email: item.customer.email,
      Location: item.customer.current_location,
    }));

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Testdrive");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    saveAs(new Blob([s2ab(excelBuffer)], { type: "" }), fileName + ".xlsx");
  };

  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const filterData = (data, modelName, startDate, endDate) => {
    if (!modelName && !startDate && !endDate) {
      return data;
    }

    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    return data.filter((item) => {
      const isModelMatch = !modelName || item.model.name === modelName;

      if (!start && !end) {
        return isModelMatch;
      }

      const createdDate = new Date(item.created_at);

      const isDateMatch =
        (!start || createdDate >= start) && (!end || createdDate <= end);
      return isModelMatch && isDateMatch;
    });
  };

  const ModelSelect = () => {
    return (
      <select
        value={selectedModel}
        className="mx-2"
        onChange={(e) => setselectedModel(e.target.value)}
      >
        <option value={""}>All</option>
        {models &&
          models.map((result) => (
            <option key={result} value={result}>
              {result}
            </option>
          ))}
      </select>
    );
  };

  return (
    <Container className={styles.home}>
      <h3 className="text-center">Test Drives</h3>
      {testdrives.length > 0 && (
        <div className={tdstyles.topSection}>
          <div
            className={tdstyles.exportBtn}
            onClick={(e) =>
              exportToCSV(
                filteredData,
                `${moment(Date.now()).format("DD-MMMM-YYYY")}-${
                  selectedModel ? selectedModel : "All Models"
                }`
              )
            }
          >
            Download XL
          </div>
          <div className={tdstyles.filter}>
            <div className={tdstyles.datepick}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd-MMMM-yyyy"
                maxDate={new Date()}
                // showMonthYearPicker
                placeholderText="Select Start Date"
              />
            </div>
            <div className={tdstyles.datepick}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd-MMMM-yyyy"
                maxDate={new Date()}
                // showMonthYearPicker
                placeholderText="Select End Date"
              />
            </div>
            <div
              className={tdstyles.clearFilter}
              onClick={() => {
                setselectedModel("");
                setStartDate();
                setEndDate();
              }}
            >
              Clear Filter
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only text-center">Loading...</span>
        </Spinner>
      ) : filteredData.length > 0 ? (
        <Table className="text-center" responsive striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>
                Model Name
                <ModelSelect />
              </th>
              <th>Customer Name</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              .slice()
              .sort((a, b) => moment(b.updated_at) - moment(a.updated_at))
              .map((testdrive, index) => (
                <tr
                  onClick={() => navigate(`/testdrive/${testdrive.id}`)}
                  key={testdrive.id}
                  className={styles.booking}
                >
                  <td>{index + 1}</td>
                  <td>{testdrive.model.name || testdrive.model_id}</td>
                  <td>{testdrive?.customer?.first_name}</td>

                  {testdrive.created_at ? (
                    <td>{`${moment(testdrive.created_at).format(
                      "DD-MMMM-YYYY"
                    )}`}</td>
                  ) : null}
                  <th>
                    <Button variant="outline-primary" size="sm">
                      View more
                    </Button>
                  </th>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <p>No testdrives found</p>
      )}
    </Container>
  );
};
export default TestDrive;
