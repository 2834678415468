import { createSlice } from "@reduxjs/toolkit";
import {
  getModel,
  getModels,
  getModelPrice,
  updateModelPrice,
  getModelOffers,
} from "../../api/models";

export const slice = createSlice({
  name: "models",
  initialState: {
    items: [],
    offers: [],
    activeModel: {},
    activeModelVariantPrice: {},
    loadingModels: true,
    loadingOffers: true,
    waitingForUpdateApi: false,
    error: {
      message: "",
    },
  },
  reducers: {
    getModelsSuccess: (state, action) => {
      state.items = action.payload;
      state.loadingModels = false;
      state.error = {};
    },
    getModelSuccess: (state, action) => {
      state.activeModel = action.payload;
      state.loadingModels = false;
      state.error = {};
    },
    getOffersSuccess: (state, action) => {
      state.offers = action.payload;
      state.loadingOffers = false;
      state.error = {};
    },
    getModelPriceSuccess: (state, action) => {
      state.activeModelVariantPrice = action.payload;
      state.loadingModels = false;
      state.error = {};
    },
    updateModelOnRoadSuccess: (state, action) => {
      state.activeModelVariantPrice = action.payload;
      state.loadingModels = false;
      state.waitingForUpdateApi = false;
    },
    getModelsFailed: (state) => {
      state.items = [];
      state.loadingModels = false;
      state.error.message = "Something went wrong";
    },
    getOfferssFailed: (state) => {
      state.offers = [];
      state.loadingOffers = false;
      state.error.message = "Something went wrong";
    },
    clearModels: (state) => {
      state.items = [];
      state.offers = [];
      state.activeModel = {};
      state.activeModelVariantPrice = {};
      state.waitingForUpdateApi = false;
      state.loadingModels = false;
    },
    setLoading: (state) => {
      state.loadingModels = true;
    },
    setOfferLoading: (state) => {
      state.loadingOffers = true;
    },
    setLoadingUpdate: (state, action) => {
      state.waitingForUpdateApi = action.payload;
    },
  },
});

export const selectModels = (state) => state.models.items || [];
export const selectOffers = (state) => state.models.offers || [];
export const selectActiveModel = (state) => state.models.activeModel || {};
export const selectActiveModelVariantPrice = (state) =>
  state.models.activeModelVariantPrice || {};
export const selectIsLoading = (state) => state.models.loadingModels;
export const selectIsOffersLoading = (state) => state.models.loadingOffers;
export const selectIsUpateAPILoading = (state) =>
  state.models.waitingForUpdateApi;

export const {
  getModelsSuccess,
  getModelSuccess,
  getOffersSuccess,
  getOfferssFailed,
  setOfferLoading,
  updateModelOnRoadSuccess,
  getModelPriceSuccess,
  getModelsFailed,
  clearModels,
  setLoading,
  setLoadingUpdate,
} = slice.actions;
export const setLoadingStatus = (loading) => (dispatch) => {
  dispatch(setLoading(loading));
};
export const fetchModels = () => async (dispatch) => {
  try {
    const models = await getModels();
    dispatch(getModelsSuccess(models.data));
  } catch (err) {
    dispatch(getModelsFailed(err.toString()));
  }
};

export const fetchModelOffers = (id) => async (dispatch) => {
  try {
    dispatch(setOfferLoading(true));
    const models = await getModelOffers(id);
    dispatch(getOffersSuccess(models.data));
  } catch (err) {
    dispatch(getOfferssFailed(err.toString()));
  }
};

export const fetchModel = (id) => async (dispatch) => {
  try {
    const model = await getModel(id);
    dispatch(getModelSuccess(model.data));
  } catch (err) {
    dispatch(getModelsFailed(err.toString()));
  }
};

export const fetchModelPriceDetails =
  (modelId, variantId) => async (dispatch) => {
    try {
      dispatch(clearModels());
      const modelOnRoadDetails = await getModelPrice(modelId, variantId);
      dispatch(getModelPriceSuccess(modelOnRoadDetails.data));
    } catch (err) {
      dispatch(getModelsFailed(err.toString()));
    }
  };

export const updateModelPriceDetails =
  (modelId, variantId, payload) => async (dispatch) => {
    try {
      dispatch(setLoadingUpdate(true));
      const modelOnRoadDetails = await updateModelPrice(
        modelId,
        variantId,
        payload
      );
      dispatch(updateModelOnRoadSuccess(modelOnRoadDetails.data));
    } catch (err) {
      dispatch(getModelsFailed(err.toString()));
    }
  };

export default slice.reducer;
