import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectShowRooms,
  fetchShowRooms,
  selectIsLoading,
  setLoadingStatus,
} from "./showRoomSlice";
import { Container, Button, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "@reach/router";
import styles from "../models/Models.module.css";

const ShowRoom = () => {
  const showRooms = useSelector(selectShowRooms);
  const isLoading = useSelector(selectIsLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchShowRooms());
  }, [dispatch]);
  return (
    <>
      <Container className={styles.home}>
        <h3 className="text-center">Showrooms</h3>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : showRooms.length > 0 ? (
          <Table className="text-center" responsive striped bordered hover>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Branches</th>
                <th>Place</th>
                <th>Contact No</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {showRooms.map((showroom, index) => (
                <tr key={showroom.id} className={styles.model}>
                  <td>{index + 1}</td>
                  <td>{showroom.name}</td>
                  <td>{showroom.place}</td>
                  <td>{showroom.contact_phone_1}</td>
                  <th>
                    <Button
                      onClick={() => navigate(`/showroom/${showroom.id}`)}
                      variant="outline-primary"
                      size="sm"
                    >
                      View more
                    </Button>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>
            No showRooms found in your system. Contact Carbae admin for details
          </p>
        )}
      </Container>
    </>
  );
};

export default ShowRoom;
