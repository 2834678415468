import React, { useEffect } from "react";
import { fetchfinances, selectIsLoading, selectfinances } from "./financeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "@reach/router";
import { Button, Container, Spinner, Table } from "react-bootstrap";
import { selectUserData } from "../login/loginSlice";

const FinanceList = ({ modelId = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const finances = useSelector(selectfinances);
  const isfinancesLoading = useSelector(selectIsLoading);
  const userData = useSelector(selectUserData);

  useEffect(() => {
    dispatch(fetchfinances(modelId));
  }, [modelId, dispatch]);

  return (
    <Container>
      {isfinancesLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only text-center">Loading...</span>
        </Spinner>
      ) : finances.length > 0 ? (
        <div>
          <h3 className="text-center">Your Finances</h3>

          <Table className="text-center" responsive striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Model</th>
                <th>Funding Percentage</th>
                <th>Funding On</th>
                <th>Min Tenure</th>
                <th>Max Tenure</th>
                <th>Interest From</th>
                <th>Intetrest To</th>
                <th>Down Payment Starts</th>
                {userData.user_type === "dealer_admin" && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {finances.map((finance, index) => (
                <tr
                  key={finance.id}
                  // className={styles.finance}
                >
                  <td>{index + 1}</td>
                  <td>{finance.model.name || finance.model_id}</td>
                  <td>{finance.funding_percentage}</td>
                  <td>{finance.funding_on}</td>
                  <td>{finance.min_tenure}</td>
                  <td>{finance.max_tenure}</td>
                  <td>{finance.interest_from}</td>
                  <td>{finance.interest_to}</td>
                  <td>{finance.down_payment_starts}</td>

                  {userData.user_type === "dealer_admin" && (
                    <th>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => navigate(`/finance/${finance.id}`)}
                      >
                        View more
                      </Button>
                    </th>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p>No Finances found</p>
      )}
    </Container>
  );
};

export default FinanceList;
