import React from "react";
import "./App.css";
import { Router, Redirect } from "@reach/router";
import Header from "../header/Header";
import Home from "../home/Home";
import Booking from "../booking/Booking";
import Login from "../login/Login";
import Models from "../models/Models";
import ModelDetails from "../models/ModelDetail";
import VariantDetails from "../models/VariantDetail";
import Footer from "../footer/Footer";
import Terms from "../terms/terms";
import Privacy from "../privacy/privacy";
import ShowRooms from "../showRoom/ShowRoom";
import ShowRoomDetails from "../showRoom/ShowRoomDeatils";
import TestDrive from "../testDrive/TestDrive";
import TestDriveDetails from "../testDriveDetails/TestDriveDetails";
import OfferTypeDetails from "../offerType/OfferTypeDetail";
import OfferType from "../offerType/OfferType";
import OfferTypeCreateForm from "../offerType/OfferTypeCreateForm";
import Offers from "../offers/Offers";
import OfferDetails from "../offers/OfferDetails";
import OfferCreate from "../offers/OfferCreate";
import OfferVariant from "../offerVariant/OfferVariant";
import Leads from "../lead/Leads";
import LeadDetails from "../lead/LeadDetails";
import Account from "../account/Account";
import AccountDetails from "../account/AccountDetails";
import CreateAccount from "../account/CreateAccount";
import { selectUserData } from "../login/loginSlice";
import { useSelector } from "react-redux";
import Exchange from "../exchange/Exchange";
import ExchangeDetails from "../exchange/ExchangeDetails";
import DashBoard from "../dashboard/DashBoard";
import FinanceCreate from "../finance/FinanceCreate";
import FinanceDetails from "../finance/FinanceDetails";
import FinanceList from "../finance/FinanceList";
import FinanceLead from "../financeLead/FinanceLead";
import FinanceLeadDeatils from "../financeLead/FinanceLeadDeatils";

function PrivateRoute({ as: Component, ...props }) {
  const userData = useSelector(selectUserData);
  const isLoggedIn =
    userData && userData.token && userData.token["access-token"];

  return isLoggedIn ? (
    <Component {...props} />
  ) : (
    <Redirect from="" to="login" noThrow />
  );
}
function DealerRoute({ as: Component, ...props }) {
  const userData = useSelector(selectUserData);
  const userType = userData && userData.user_type;

  return userType === "dealer_admin" ? (
    <Component {...props} />
  ) : (
    <Redirect from="" to="/" noThrow />
  );
}

function ShowroomRoute({ as: Component, ...props }) {
  const userData = useSelector(selectUserData);
  const userType = userData && userData.user_type;

  return userType === "showroom_admin" ? (
    <Component {...props} />
  ) : (
    <Redirect from="" to="/" noThrow />
  );
}

function App() {
  return (
    <>
      <div>
        {/* showroom and dealer redirect */}
        <Router primary={false}>
          <Header default />
        </Router>
        <Router>
          <Login path="login" />

          <PrivateRoute as={DashBoard} path="/" />

          <PrivateRoute as={Home} path="/booking" />
          <PrivateRoute as={Booking} path="/bookings/:bookingId" />

          <PrivateRoute as={Models} path="/models" />
          <PrivateRoute as={ModelDetails} path="/models/:modelId" />

          <PrivateRoute as={TestDrive} path="/testdrives" />
          <PrivateRoute as={TestDriveDetails} path="/testdrive/:testdriveId" />

          <ShowroomRoute as={Leads} path="leads" />
          <ShowroomRoute as={LeadDetails} path="/lead/:leadId" />

          <ShowroomRoute as={Exchange} path="exchange" />
          <ShowroomRoute as={ExchangeDetails} path="/exchange/:exchangeId" />

          <DealerRoute as={ShowRooms} path="showroom" />
          <DealerRoute as={ShowRoomDetails} path="/showroom/:showroomId" />

          <DealerRoute
            as={VariantDetails}
            path="/models/:modelId/variant/:variantId"
          />

          <DealerRoute as={Account} path="accounts" />
          <DealerRoute as={AccountDetails} path="/account/:accountId" />
          <DealerRoute as={CreateAccount} path="/account-create" />

          <DealerRoute as={OfferType} path="/offertype" />
          <DealerRoute as={OfferTypeDetails} path="/offertype/:offertypeId" />
          <DealerRoute as={OfferTypeCreateForm} path="/offertype-create" />

          <ShowroomRoute as={FinanceLead} path="/financeLead" />
          <ShowroomRoute
            as={FinanceLeadDeatils}
            path="/financeLead/:financeLeadId"
          />

          <DealerRoute as={FinanceDetails} path="/finance/:financeId" />
          <DealerRoute as={FinanceList} path="/finances" />
          <DealerRoute
            as={FinanceCreate}
            path="/create-finance/Model/:ModelId"
          />

          <DealerRoute as={Offers} path="/offers" />
          <DealerRoute as={OfferDetails} path="/offers/:offerId" />
          <DealerRoute
            as={OfferCreate}
            path="/create-offer/:applicableType/:applicableId"
          />
          <DealerRoute
            as={OfferVariant}
            path="/offers/:offerId/variant-offers/:variantOfferId"
          />

          <PrivateRoute as={Terms} path="terms-and-conditions" />
          <PrivateRoute as={Privacy} path="privacy-policy" />
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;
