import React from "react";
import { ExchangeData } from "./data";
import styles from "./Exchange.module.css";
const ExchangeDetails = ({ exchangeId }) => {
  const exchangeData = ExchangeData.find(
    (data) => data.id === parseInt(exchangeId)
  );

  const minor_color = `mb-2 ${styles.text_color}`;

  return (
    <div className={styles.booking}>
      <h3 className={styles.heading}>Exchange details</h3>
      <div className={styles.container}>
        <div className={styles.bookingCard}>
          <div className={`${styles.box} ${styles.center}`}>
            <div>
              {/* <h1>Customer Details</h1> */}
              <p className={minor_color}>
                Name: <strong>{exchangeData.customer_name}</strong>
              </p>
              <p className={minor_color}>
                Phone: <strong>{exchangeData.ph_no} </strong>
              </p>
              <p className={minor_color}>
                Email: <strong>{exchangeData.email} </strong>
              </p>
              <p className={minor_color}>
                Location: <strong>{exchangeData.location} </strong>
              </p>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.margin}>
              <p className={minor_color}>
                Brand : <strong>{exchangeData.brand_name}</strong>
              </p>
              <p className={minor_color}>
                Model : <strong>{exchangeData.model_name}</strong>
              </p>
              <p className={minor_color}>
                Fuel : <strong>{exchangeData.fuel_type}</strong>
              </p>
              <p className={minor_color}>
                Make Year : <strong>{exchangeData.make_year}</strong>
              </p>
              <p className={minor_color}>
                Kilometers run : <strong>{exchangeData.km}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeDetails;
