import React, { useState } from "react";
import { useEffect } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  selectIsPostAPILoading,
  postofferDetails,
  selectPostAPIError,
  resetError,
} from "./offerSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchofferTypes, selectofferTypes } from "../offerType/offerTypeSlice";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "@reach/router";
import moment from "moment/moment";

const OfferUpdate = ({ applicableType, applicableId }) => {
  const isLoading = useSelector(selectIsPostAPILoading);
  const offerTypes = useSelector(selectofferTypes);
  const offerCreateErrors = useSelector(selectPostAPIError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [postError, setPostError] = useState(false);
  const [errors, setErrors] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState({
    name: "",
    description: "",
    is_active: true,
    is_optional: false,
    is_additional: false,
    offer_type_id: "",
    valuation: "flat",
    amount: "",
    start_date: "",
    end_date: "",
    applicable_type: applicableType,
    applicable_id: parseFloat(applicableId),
  });

  useEffect(() => {
    if (!["Brand", "Model", "Variant"].includes(applicableType)) {
      setErrors("Applicable Type Error");
      return;
    }
    if (!applicableId) {
      setErrors("Applicable Id Error");
      return;
    }
  }, [applicableType, applicableId]);

  useEffect(() => {
    if (!!offerCreateErrors.message) {
      setPostError(true);
      return;
    }
  }, [offerCreateErrors]);

  useEffect(() => {
    dispatch(fetchofferTypes());
    setPostError(false);
    dispatch(resetError());
  }, [dispatch]);

  const startDateHandler = (date) => {
    setStartDate(date);
    // date = new Date(date).toLocaleDateString("en-CA");
    date = moment(date).format("yyyy-MM-DD");
    setData({ ...data, start_date: date });
  };

  const endDateHandler = (date) => {
    setEndDate(date);
    // date = new Date(date).toLocaleDateString("en-CA");
    date = moment(date).format("yyyy-MM-DD");
    setData({ ...data, end_date: date });
  };

  const submitHandler = () => {
    if (
      !data.amount ||
      !data.applicable_id ||
      !data.applicable_type ||
      !data.description ||
      !data.end_date ||
      !data.name ||
      !data.offer_type_id ||
      !data.start_date ||
      !data.valuation
    ) {
      setShow(true);
      return;
    }
    dispatch(postofferDetails(data, navigate));
  };

  return (
    <Container className="my-5">
      {errors.length > 0 ? (
        <p> {errors}</p>
      ) : !!offerTypes.length ? (
        <>
          {show && (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>Complete the form before submitting the values</p>
            </Alert>
          )}{" "}
          {postError && (
            <Alert
              variant="danger"
              onClose={() => {
                dispatch(resetError());
                setPostError(false);
              }}
              dismissible
            >
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>{offerCreateErrors.message}</p>
            </Alert>
          )}{" "}
          <h3 className="text-center">Create Offer </h3>
          <br />
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Name :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  placeholder="Enter Name"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Description :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="textarea"
                  rows={3}
                  className="font-weight-bold"
                  placeholder="Enter Description"
                  value={data.description}
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Form.Check
                  label="Is Active"
                  id="disabled-custom-switch"
                  checked={data.is_active}
                  onChange={(e) =>
                    setData({ ...data, is_active: e.target.checked })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Form.Check
                  label="Is Additional"
                  id="disabled-custom-switch"
                  checked={data.is_additional}
                  onChange={(e) =>
                    setData({ ...data, is_additional: e.target.checked })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Form.Check
                  label="Is Optional"
                  id="disabled-custom-switch"
                  checked={data.is_optional}
                  onChange={(e) =>
                    setData({ ...data, is_optional: e.target.checked })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Offer Type :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  value={data.offer_type_id}
                  onChange={(e) =>
                    setData({
                      ...data,
                      offer_type_id: parseFloat(e.target.value),
                    })
                  }
                >
                  <option disabled value="">
                    Select Offer Type
                  </option>
                  {offerTypes.map((types) => (
                    <option key={types.id} value={types.id}>
                      {types.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Amount :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  value={data.amount || ""}
                  onChange={(e) =>
                    setData({ ...data, amount: parseFloat(e.target.value) })
                  }
                />
              </Col>
            </Form.Group>

            {/* <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Valuation :
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    as="select"
                    value={data.valuation}
                    onChange={(e) =>
                      setData({ ...data, valuation: e.target.value })
                    }
                  >
                    <option disabled value="">
                      Select Valuation
                    </option>
                    <option value="flat">Flat</option>
                    <option value="percentage">Percentage</option>
                  </Form.Control>
                </Col>
              </Form.Group> */}

            {/* {startDate && endDate && ( */}
            <>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Start Date :
                </Form.Label>
                <Col sm="9">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => startDateHandler(date)}
                    onFocus={(e) => e.target.blur()}
                    minDate={new Date()}
                    dateFormat="yyyy-MM-d"
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  End Date :
                </Form.Label>
                <Col sm="9">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => endDateHandler(date)}
                    onFocus={(e) => e.target.blur()}
                    dateFormat="yyyy-MM-d"
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                  />
                </Col>
              </Form.Group>
            </>
            {/* )} */}

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Applicable Type :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  disabled
                  value={applicableType}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Applicable Id :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  disabled
                  value={applicableId}
                />
              </Col>
            </Form.Group>
            <div className="col text-center mt-5">
              <Button variant="primary" onClick={submitHandler}>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <>
          <h3>No OfferType Found. First Create OfferType</h3>
          <Link to="/offertype-create">Click here to create OfferType</Link>
        </>
      )}
    </Container>
  );
};

export default OfferUpdate;
