import { createSlice } from "@reduxjs/toolkit";
import { getBooking } from "../../api/bookings";

export const slice = createSlice({
  name: "booking",
  initialState: {
    booking: [],
    loadingBooking: true,
    error: {
      message: "",
    },
  },
  reducers: {
    getBookingSuccess: (state, action) => {
      state.booking = action.payload;
      state.loadingBooking = false;
      state.error = {};
    },
    getBookingFailed: (state) => {
      state.booking = [];
      state.loadingBooking = false;
      state.error.message = "Something went wrong";
    },
    clearBooking: (state) => {
      state.booking = [];
      state.loadingBooking = true;
      state.error = {
        message: "",
      };
    },
  },
});

export const selectBooking = (state) => state.booking.booking || [];
export const selectIsLoading = (state) => state.booking.loadingBooking;

export const { getBookingSuccess, getBookingFailed, clearBooking } =
  slice.actions;
export const fetchBooking = (id) => async (dispatch) => {
  try {
    const bookingsDetails = await getBooking(id);
    dispatch(getBookingSuccess(bookingsDetails.data));
  } catch (err) {
    dispatch(getBookingFailed(err.toString()));
  }
};

export default slice.reducer;
