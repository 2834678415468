import { createSlice } from "@reduxjs/toolkit";
import {
  deleteUser,
  updateUser,
  getUser,
  createUser,
  getUsers,
} from "../../api/account";

export const slice = createSlice({
  name: "account",
  initialState: {
    users: [],
    user: {},
    loading: true,
    waitingForUpdateApi: false,
    waitingForCreateApi: false,
    error: {
      message: "",
    },
  },
  reducers: {
    getUsersSuccess: (state, action) => {
      state.users = action.payload;
      state.loading = false;
      state.error = {};
    },
    getUserSuccess: (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.error = {};
    },

    getUsersFailed: (state) => {
      state.users = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    getUserFailed: (state) => {
      state.user = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },

    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingUpdate: (state, action) => {
      state.waitingForUpdateApi = action.payload;
    },
    setLoadingPost: (state, action) => {
      state.waitingForCreateApi = action.payload;
    },
  },
});

export const selectUser = (state) => state.account.user || [];
export const selectUsers = (state) => state.account.users || [];
export const selectIsLoading = (state) => state.account.loading;
export const selectIsUpateAPILoading = (state) =>
  state.account.waitingForUpdateApi;
export const selectIsPostAPILoading = (state) =>
  state.account.waitingForCreateApi;

export const {
  getUsersSuccess,
  getUserSuccess,
  setLoading,
  getUserFailed,
  getUsersFailed,
  setLoadingUpdate,
  setLoadingPost,
} = slice.actions;
export const setLoadingStatus = (loading) => (dispatch) => {
  dispatch(setLoading(loading));
};
export const fetchUsers = () => async (dispatch) => {
  try {
    const users = await getUsers();
    dispatch(getUsersSuccess(users.data));
  } catch (err) {
    dispatch(getUsersFailed(err.toString()));
  }
};

export const fetchUser = (id) => async (dispatch) => {
  try {
    const user = await getUser(id);
    dispatch(getUserSuccess(user.data));
  } catch (err) {
    dispatch(getUserFailed(err.toString()));
  }
};

export const createUserDetails = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoadingPost(true));
    await createUser(payload);
    dispatch(setLoadingPost(false));
    navigate("/accounts");
  } catch (err) {
    dispatch(getUserFailed(err.toString()));
  }
};
export const updateUserDetails = (Id, payload) => async (dispatch) => {
  try {
    dispatch(setLoadingUpdate(true));
    await updateUser(Id, payload);
    dispatch(setLoadingUpdate(false));
  } catch (err) {
    dispatch(getUserFailed(err.toString()));
  }
};

export const deleteUserDetails = (Id, navigate) => async (dispatch) => {
  try {
    await deleteUser(Id);
    navigate("/accounts");
  } catch (err) {
    dispatch(getUserFailed(err.toString()));
  }
};

export default slice.reducer;
