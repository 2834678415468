import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchBooking, selectBooking, selectIsLoading } from "./bookingSlice";

import styles from "./Booking.module.css";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import Image from "../../atoms/Images/Image";

// import ProdCard from "../../components/molecules/prodCard/ProdCard";
const Booking = ({ bookingId = "" }) => {
  const booking = useSelector(selectBooking);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBooking(bookingId));
  }, [bookingId, dispatch]);

  const minor_color = `mb-2 ${styles.text_color}`;

  return (
    <div className={styles.booking}>
      <h3 className={styles.heading}>Booking details</h3>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <div className={styles.container}>
          <div className={styles.bookingCard}>
            <div className={`${styles.box} ${styles.center}`}>
              <div>
                {/* <h1>Customer Details</h1> */}
                <p className={minor_color}>
                  Name:{" "}
                  <strong>
                    {booking.customer.first_name} {booking.customer.last_name}
                  </strong>
                </p>
                <p className={minor_color}>
                  Phone: <strong>{booking.customer.phone} </strong>
                </p>
                <p className={minor_color}>
                  Email: <strong>{booking.customer.email} </strong>
                </p>
                {booking?.customer?.current_location && (
                  <div className={`${minor_color} d-flex`}>
                    <p>Location: </p>
                    <p>
                      <strong>{booking.customer.current_location}</strong>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.box}>
              <h2 className={styles.heading}>{booking.model.name}</h2>
              {booking.status === "payment_successful" && (
                <Image
                  src={booking.color.display_image}
                  className={styles.img}
                />
              )}
              <div className={styles.textcenter}>
                <p className={minor_color}>{booking.color.name}</p>
                <p className={minor_color}>
                  {booking.variant.name} - {booking.variant.transmission_type}
                </p>
                {booking.status === "payment_successful" && (
                  <p className={minor_color}>
                    Booking Amount - {booking.booking_amount}
                  </p>
                )}
                <p className={minor_color}>
                  {booking.status === "payment_successful" ? (
                    <Badge variant="success p-3">{booking.status}</Badge>
                  ) : (
                    <Badge variant="primary p-3">{booking.status}</Badge>
                  )}
                </p>
              </div>
            </div>
            <div className={`${styles.box} ${styles.center}`}>
              {booking.showroom ? (
                <p className={minor_color}>
                  Showroom : <strong> {booking.showroom.place}</strong>
                </p>
              ) : null}
              {booking.created_at ? (
                <p className={minor_color}>
                  Booked on :{" "}
                  <strong>
                    {" "}
                    {moment(new Date(booking.created_at)).format(
                      " h:mm a , MMM-D-YYYY"
                    )}
                  </strong>
                </p>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Booking;
