import { createSlice } from "@reduxjs/toolkit";
import {
  deleteFinance,
  updateFinance,
  getFinance,
  postFinance,
  getFinances,
} from "../../api/finance";

export const slice = createSlice({
  name: "finance",
  initialState: {
    finances: [],
    finance: {},
    loading: true,
    waitingForUpdateApi: false,
    waitingForPostApi: false,
    error: {
      message: "",
    },
  },
  reducers: {
    getFinancesSuccess: (state, action) => {
      state.finances = action.payload;
      state.loading = false;
      state.error = {};
    },
    getFinanceSuccess: (state, action) => {
      state.finance = action.payload;
      state.loading = false;
      state.error = {};
    },

    getFinancesFailed: (state) => {
      state.finances = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    getFinanceFailed: (state) => {
      state.finance = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    postFinanceFailed: (state, action) => {
      state.finance = [];
      state.waitingForPostApi = false;
      state.error.message = action.payload;
    },

    setLoading: (state) => {
      state.loading = true;
    },
    resetError: (state) => {
      state.error = {};
    },
    setLoadingUpdate: (state, action) => {
      state.waitingForUpdateApi = action.payload;
    },

    setLoadingPost: (state, action) => {
      state.waitingForPostApi = action.payload;
    },
  },
});

export const selectfinance = (state) => state.finance.finance || [];
export const selectfinances = (state) => state.finance.finances || [];
export const selectIsLoading = (state) => state.finance.loading;
export const selectIsUpateAPILoading = (state) =>
  state.finance.waitingForUpdateApi;
export const selectIsPostAPILoading = (state) =>
  state.finance.waitingForPostApi;
export const selectPostAPIError = (state) => state.finance.error;

export const {
  getFinancesSuccess,
  getFinanceSuccess,
  setLoading,
  getFinanceFailed,
  getFinancesFailed,
  postFinanceFailed,
  resetError,
  setLoadingUpdate,
  setLoadingPost,
} = slice.actions;
export const setLoadingStatus = (loading) => (dispatch) => {
  dispatch(setLoading(loading));
};
export const fetchfinances = () => async (dispatch) => {
  try {
    setLoadingStatus(true);
    const finances = await getFinances();
    dispatch(getFinancesSuccess(finances.data));
  } catch (err) {
    dispatch(getFinancesFailed(err.toString()));
  }
};

export const fetchfinance = (id) => async (dispatch) => {
  try {
    const finance = await getFinance(id);
    dispatch(getFinanceSuccess(finance.data));
  } catch (err) {
    dispatch(getFinanceFailed(err.toString()));
  }
};

export const postFinanceDetails = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoadingPost(true));
    const response = await postFinance(payload);
    if (response.status === "error") {
      throw new Error(response.message);
    }
    dispatch(setLoadingPost(false));
    navigate(`/finances`);
  } catch (err) {
    dispatch(postFinanceFailed(err.toString()));
  }
};
export const updateFinanceDetails = (Id, payload) => async (dispatch) => {
  try {
    dispatch(setLoadingUpdate(true));
    const finance = await updateFinance(Id, payload);
    dispatch(getFinanceSuccess(finance.data));
    dispatch(setLoadingUpdate(false));
  } catch (err) {
    dispatch(getFinanceFailed(err.toString()));
  }
};

export const deleteFinanceDetails = (Id, navigate) => async (dispatch) => {
  try {
    await deleteFinance(Id);
    navigate(`/finances`);

    // navigate to models
  } catch (err) {
    dispatch(getFinanceFailed(err.toString()));
  }
};

export default slice.reducer;
