import { createSlice } from "@reduxjs/toolkit";
import {
  deleteOfferType,
  putOfferType,
  getOfferType,
  postOfferTypes,
  getOfferTypes,
} from "../../api/offerTypes";

export const slice = createSlice({
  name: "offerType",
  initialState: {
    offerTypes: [],
    offerType: {},
    loading: true,
    waitingForUpdateApi: false,
    waitingForPostApi: false,
    error: {
      message: "",
    },
  },
  reducers: {
    getofferTypesSuccess: (state, action) => {
      state.offerTypes = action.payload;
      state.loading = false;
      state.error = {};
    },
    getofferTypeSuccess: (state, action) => {
      state.offerType = action.payload;
      state.loading = false;
      state.error = {};
    },

    getofferTypesFailed: (state) => {
      state.offerTypes = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    getofferTypeFailed: (state) => {
      state.offerType = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },

    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingUpdate: (state, action) => {
      state.waitingForUpdateApi = action.payload;
    },
    setLoadingPost: (state, action) => {
      state.waitingForPostApi = action.payload;
    },
  },
});

export const selectofferType = (state) => state.offerType.offerType || [];
export const selectofferTypes = (state) => state.offerType.offerTypes || [];
export const selectIsLoading = (state) => state.offerType.loading;
export const selectIsUpateAPILoading = (state) =>
  state.offerType.waitingForUpdateApi;
export const selectIsPostAPILoading = (state) =>
  state.offerType.waitingForPostApi;

export const {
  getofferTypesSuccess,
  getofferTypeSuccess,
  setLoading,
  getofferTypeFailed,
  getofferTypesFailed,
  setLoadingUpdate,
  setLoadingPost,
} = slice.actions;
export const setLoadingStatus = (loading) => (dispatch) => {
  dispatch(setLoading(loading));
};
export const fetchofferTypes = () => async (dispatch) => {
  try {
    const offerTypes = await getOfferTypes();
    dispatch(getofferTypesSuccess(offerTypes.data));
  } catch (err) {
    dispatch(getofferTypesFailed(err.toString()));
  }
};

export const fetchofferType = (id) => async (dispatch) => {
  try {
    const offerType = await getOfferType(id);
    dispatch(getofferTypeSuccess(offerType.data));
  } catch (err) {
    dispatch(getofferTypeFailed(err.toString()));
  }
};

export const postofferTypeDetails = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoadingPost(true));
    await postOfferTypes(payload);
    dispatch(setLoadingPost(false));
    navigate("/offertype");
  } catch (err) {
    dispatch(getofferTypeFailed(err.toString()));
  }
};
export const updateofferTypeDetails = (Id, payload) => async (dispatch) => {
  try {
    dispatch(setLoadingUpdate(true));
    await putOfferType(Id, payload);
    dispatch(setLoadingUpdate(false));
  } catch (err) {
    dispatch(getofferTypeFailed(err.toString()));
  }
};

export const deleteofferTypeDetails = (Id, navigate) => async (dispatch) => {
  try {
    await deleteOfferType(Id);
    navigate("/offertype");
  } catch (err) {
    dispatch(getofferTypeFailed(err.toString()));
  }
};

export default slice.reducer;
