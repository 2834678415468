import { getApi, postApi } from "./utils";

export const getModels = () => {
  return getApi("models");
};

export const getModel = (id) => {
  return getApi(`models/${id}`);
};

export const getModelOffers = (id) => {
  return getApi(`models/${id}/total_offers`);
};

export const getModelPrice = (modelId, variantId) => {
  return getApi(`models/${modelId}/variants/${variantId}/price`);
};

export const updateModelPrice = (modelId, variantId, updatedValues) => {
  return postApi(
    `models/${modelId}/variants/${variantId}/set_price`,
    updatedValues
  );
};
