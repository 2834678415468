import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import styles from "../../features/models/Models.module.css";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import {
  fetchModel,
  selectActiveModel,
  selectIsLoading,
  setLoadingStatus,
} from "../../features/models/modelsSlice";
import { useNavigate } from "@reach/router";
import Button from "react-bootstrap/Button";
import { TransmissionType, FuelType } from "../../features/data";
import { filterArray } from "../../features/models/Utils";
import { selectUserData } from "../../features/login/loginSlice";

const VariantList = ({ modelId = "" }) => {
  const model = useSelector(selectActiveModel);
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);

  const [Fuel, setFuel] = useState("");
  const [Type, setType] = useState("");
  const [orderedVariants, setorderedVariants] = useState();
  const [filteredVariants, setFilteredVariants] = useState("");
  const [transmission_type, setTransmission_type] = useState("");
  const [fuel_type, setFuel_type] = useState("");

  useEffect(() => {
    if (model.variants) {
      setFuel([...new Set(model.variants.map((model) => model["fuel_type"]))]);
      setType([
        ...new Set(model.variants.map((model) => model["transmission_type"])),
      ]);
      const filteredVariants = filterArray(model.variants, {
        transmission_type,
        fuel_type,
      });
      setFilteredVariants(filteredVariants);
    }
  }, [model, fuel_type, transmission_type]);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchModel(modelId));
  }, [modelId, dispatch]);

  useEffect(() => {
    const Variants = filteredVariants
      ? [...filteredVariants]?.sort((a, b) => {
          if (a.ex_showroom_starting < b.ex_showroom_starting) {
            return -1;
          }
          if (a.ex_showroom_starting > b.ex_showroom_starting) {
            return 1;
          }
          return 0;
        })
      : [];
    setorderedVariants(Variants);
  }, [filteredVariants]);

  const FuelSelect = () => {
    return (
      <select value={fuel_type} onChange={(e) => setFuel_type(e.target.value)}>
        <option value={""}>All</option>
        {Fuel &&
          Fuel.map((result) => (
            <option key={result} value={result}>
              {FuelType[result]}
            </option>
          ))}
      </select>
    );
  };
  const TypeSelect = () => {
    return (
      <select
        value={transmission_type}
        onChange={(e) => setTransmission_type(e.target.value)}
      >
        <option value={""}>All</option>
        {Type &&
          Type.map((result) => (
            <option key={result} value={result}>
              {TransmissionType[result]}
            </option>
          ))}
      </select>
    );
  };
  return (
    <div className={` ${styles.home}`}>
      <h3 className="text-center">
        {model.name ? `${model.name} Variants` : `Variants`}
      </h3>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <Table className="text-center" responsive striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>Variant Name</th>
              <th>
                Transmission <TypeSelect />
              </th>
              <th>
                Fuel <FuelSelect />
              </th>
              <th>On-Road Starting</th>
              {userData.user_type === "dealer_admin" && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {orderedVariants &&
              orderedVariants.map((variant, index) => (
                <tr key={variant.id} className={styles.model}>
                  <td>{index + 1}</td>
                  <td>{variant.name}</td>
                  <td>{TransmissionType[variant.transmission_type]}</td>
                  <td>{FuelType[variant.fuel_type]}</td>
                  <td>{variant.ex_showroom_starting}</td>
                  {userData.user_type === "dealer_admin" && (
                    <th>
                      <div className={styles.actions}>
                        <Button
                          onClick={() =>
                            navigate(`/create-offer/Variant/${variant.id}`)
                          }
                          variant="outline-success"
                          size="sm"
                        >
                          Create Offer
                        </Button>
                        <Button
                          onClick={() =>
                            navigate(`/models/${modelId}/variant/${variant.id}`)
                          }
                          variant="outline-primary"
                          size="sm"
                        >
                          View more
                        </Button>
                      </div>
                    </th>
                  )}
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
export default VariantList;
