import { createSlice } from "@reduxjs/toolkit";
import { getTestDrives } from "../../api/testdrive";

export const slice = createSlice({
  name: "testdrive",
  initialState: {
    testdrives: [],
    loadingTestDrives: true,
    error: {
      message: "",
    },
  },
  reducers: {
    getTestDrivesSuccess: (state, action) => {
      state.testdrives = action.payload;
      state.loadingTestDrives = false;
      state.error = {};
    },
    getTestDriveFailed: (state) => {
      state.testdrives = [];
      state.loadingTestDrives = false;
      state.error.message = "Something went wrong";
    },

    clearTestDrives: (state) => {
      state.testdrives = [];
      state.loadingTestDrives = true;
      state.error = {
        message: "",
      };
    },
  },
});

export const selectTestDrives = (state) => state.testdrive.testdrives || [];
export const selectIsLoading = (state) => state.testdrive.loadingTestDrives;

export const { getTestDrivesSuccess, getTestDriveFailed, clearTestDrives } =
  slice.actions;
export const fetchTestDrives = () => async (dispatch) => {
  try {
    const testdrivesDetails = await getTestDrives();
    dispatch(getTestDrivesSuccess(testdrivesDetails.data));
  } catch (err) {
    dispatch(getTestDriveFailed(err.toString()));
  }
};

export default slice.reducer;
