import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchoffers,
  selectIsLoading,
  selectoffers,
} from "../offers/offerSlice";
import { Link, useNavigate } from "@reach/router";
import { Button, Container, Spinner, Table } from "react-bootstrap";
import styles from "./offer.module.css";
import { selectUserData } from "../login/loginSlice";

const Offers = () => {
  const offers = useSelector(selectoffers);
  const isLoadingOffers = useSelector(selectIsLoading);
  const userData = useSelector(selectUserData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchoffers());
  }, [dispatch]);

  return (
    <>
      <Container className={styles.createButtonContainer}>
        {userData.brand && (
          <Link
            className={styles.createLinkButton}
            to={`/create-offer/Brand/${userData.brand.id}`}
          >
            Create Dealer Offer
          </Link>
        )}
      </Container>
      <Container

      // lassName={styles.home}
      >
        <h3 className="text-center">Your Offers</h3>
        {isLoadingOffers ? (
          <Spinner animation="border" role="status">
            <span className="sr-only text-center">Loading...</span>
          </Spinner>
        ) : offers.length > 0 ? (
          <Table className="text-center" responsive striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Applicable Type</th>
                <th>Applicable Name</th>
                <th>Offer Type</th>
                <th>Status</th>
                <th>Offer Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {offers.map((offer, index) => (
                <tr
                  onClick={() => navigate(`/offers/${offer.id}`)}
                  key={offer.id}
                  // className={styles.offer}
                >
                  <td>{index + 1}</td>
                  <td>{offer.name}</td>
                  <td>{offer.applicable_type}</td>
                  <td>{offer.applicable.name}</td>
                  <td>{offer.offer_type.name}</td>
                  <td>{offer.is_active ? "Active" : "Not Active"}</td>
                  <td>
                    {offer.amount}
                    {offer.valuation === "flat" ? " Rs " : " % "}
                  </td>

                  <th>
                    <Button variant="outline-primary" size="sm">
                      View more
                    </Button>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No offers found</p>
        )}
      </Container>
    </>
  );
};

export default Offers;
