import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import userReducer from "./features/login/loginSlice";
import bookingsReducer from "./features/home/homeSlice";
import bookingReducer from "./features/booking/bookingSlice";
import modelsReducer from "./features/models/modelsSlice";
import showRoomReducer from "./features/showRoom/showRoomSlice";
import testDriveReducer from "./features/testDrive/testDriveSlice";
import testDriveDetailsReducer from "./features/testDriveDetails/testDriveDetailsSlice";
import offerTypeReducer from "./features/offerType/offerTypeSlice";
import offersReducer from "./features/offers/offerSlice";
import offerVariantReducer from "./features/offerVariant/offerVariantSlice";
import leadsReducer from "./features/lead/leadSlice";
import accountReducer from "./features/account/accountSlice";
import financeReducer from "./features/finance/financeSlice";
import financeLeadReducer from "./features/financeLead/financeLeadSlice";

import { save, load } from "redux-localstorage-simple";

export default configureStore({
  reducer: {
    user: userReducer,
    bookings: bookingsReducer,
    models: modelsReducer,
    showroom: showRoomReducer,
    booking: bookingReducer,
    testdrive: testDriveReducer,
    testdrivedetail: testDriveDetailsReducer,
    offerType: offerTypeReducer,
    offer: offersReducer,
    offerVariant: offerVariantReducer,
    lead: leadsReducer,
    account: accountReducer,
    finance: financeReducer,
    financeLead: financeLeadReducer,
  },
  middleware: [
    ...getDefaultMiddleware(),
    save({ states: ["user"], namespace: "carbaeDealer" }),
  ],
  preloadedState: {
    ...load({ states: ["user"], namespace: "carbaeDealer" }),
  },
});
