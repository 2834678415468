import { deleteApi, getApi, postApi, putApi } from "./utils";

export const getOffers = () => {
  return getApi("offers");
};

export const postOffer = (data) => {
  return postApi(`offers`, data);
};

export const getOffer = (id) => {
  return getApi(`offers/${id}`);
};

export const putOffer = (id, data) => {
  return putApi(`offers/${id}`, data);
};

export const putBulkOffer = (id, data) => {
  return postApi(`offers/${id}/variant_offers/bulk_update`, data);
};

export const deleteOffer = (id) => {
  return deleteApi(`offers/${id}`);
};
