import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectofferTypes,
  fetchofferTypes,
  selectIsLoading,
  setLoadingStatus,
} from "./offerTypeSlice";
import { Container, Button, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate } from "@reach/router";
import styles from "./offerType.module.css";

const OfferType = () => {
  const offerTypes = useSelector(selectofferTypes);
  const isLoading = useSelector(selectIsLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchofferTypes());
  }, [dispatch]);
  return (
    <>
      <Container className={styles.createButtonContainer}>
        <Link className={styles.createLinkButton} to="/offertype-create">
          Create Offer Type
        </Link>
      </Container>
      <Container
      //   className={styles.home}
      >
        <h3 className="text-center">Offer Types</h3>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : offerTypes.length > 0 ? (
          <Table className="text-center" responsive striped bordered hover>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Name</th>
                <th>Condition</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {offerTypes.map((offerType, index) => (
                <tr
                  key={offerType.id}
                  // className={styles.model}
                >
                  <td>{index + 1}</td>
                  <td>{offerType.name}</td>
                  <td>{offerType.condition}</td>
                  <td>{offerType.description}</td>
                  <th>
                    <Button
                      onClick={() => navigate(`/offertype/${offerType.id}`)}
                      variant="outline-primary"
                      size="sm"
                    >
                      View more
                    </Button>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No offerTypes found in your system.</p>
        )}
      </Container>
    </>
  );
};

export default OfferType;
