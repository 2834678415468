import React, { useState, useEffect } from "react";
import { useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";

import { createUserDetails, selectIsPostAPILoading } from "./accountSlice";
import { selectShowRooms, fetchShowRooms } from "../showRoom/showRoomSlice";

const CreateAccount = () => {
  const isLoading = useSelector(selectIsPostAPILoading);
  const showRooms = useSelector(selectShowRooms);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchShowRooms());
  }, [dispatch]);

  const [show, setShow] = useState(false);

  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    dealer_id: "",
    phone: "",
    showroom_id: "",
    user_type: "",
    is_active: true,
    password: "",
    password_confirmation: "",
  });

  const submitHandler = () => {
    if (
      !data.email ||
      !data.first_name ||
      !data.last_name ||
      !data.showroom_id ||
      !data.dealer_id ||
      !data.phone ||
      !data.user_type ||
      !data.password ||
      !data.password_confirmation ||
      data.password !== data.password_confirmation
    ) {
      setShow(true);
      return;
    }
    dispatch(createUserDetails(data, navigate));
  };

  return (
    <Container className="my-5">
      {!!showRooms.length && (
        <>
          {show && (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>Complete the form before submitting the values</p>
            </Alert>
          )}{" "}
          <h3 className="text-center">Create Account </h3>
          <br />
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Email :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="email"
                  className="font-weight-bold"
                  placeholder="Enter Email"
                  value={data.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                First Name :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  className="font-weight-bold"
                  placeholder="Enter First Name"
                  value={data.first_name}
                  onChange={(e) =>
                    setData({ ...data, first_name: e.target.value })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Last Name :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  className="font-weight-bold"
                  placeholder="Enter Last Name"
                  value={data.last_name}
                  onChange={(e) =>
                    setData({ ...data, last_name: e.target.value })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Showroom :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  value={data.showroom_id}
                  onChange={(e) => {
                    const selectedShowroom = showRooms.find(
                      (showRoom) => showRoom.id === parseFloat(e.target.value)
                    );
                    setData({
                      ...data,
                      showroom_id: selectedShowroom.id,
                      dealer_id: selectedShowroom.dealer_id,
                    });
                  }}
                >
                  <option disabled value="">
                    Select Showroom
                  </option>
                  {showRooms.map((showRoom) => (
                    <option key={showRoom.id} value={showRoom.id}>
                      {showRoom.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Phone :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  className="font-weight-bold"
                  placeholder="Enter Phone Number"
                  value={data.phone || ""}
                  onChange={(e) =>
                    setData({ ...data, phone: parseFloat(e.target.value) })
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                User Type :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  value={data.user_type}
                  onChange={(e) =>
                    setData({ ...data, user_type: e.target.value })
                  }
                >
                  <option disabled value="">
                    Select User Type
                  </option>
                  {/* <option value="dealer_admin">Dealer Admin </option> */}
                  <option value="showroom_admin">Showroom Admin</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Password :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="password"
                  className="font-weight-bold"
                  placeholder="Enter Password"
                  value={data.password}
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Confirm Password :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="password"
                  className="font-weight-bold"
                  placeholder="Confirm Password"
                  value={data.password_confirmation}
                  onChange={(e) =>
                    setData({
                      ...data,
                      password_confirmation: e.target.value,
                    })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Form.Check
                  label="Is Active"
                  id="disabled-custom-switch"
                  checked={data.is_active}
                  onChange={(e) =>
                    setData({ ...data, is_active: e.target.checked })
                  }
                />
              </Col>
            </Form.Group>

            <div className="col text-center mt-5">
              <Button variant="primary" onClick={submitHandler}>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Container>
  );
};

export default CreateAccount;
