import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectlead,
  selectIsLeadLoading,
  fetchlead,
  updateLead,
} from "./leadSlice";
import styles from "./Lead.module.css";
import { Badge, Button, Spinner } from "react-bootstrap";
import moment from "moment";
import Edit from "./edit.svg";
import { Status } from "../data";
const LeadDetails = ({ leadId = "" }) => {
  const lead = useSelector(selectlead);
  const isLoading = useSelector(selectIsLeadLoading);

  const [edit, setEdit] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchlead(leadId));
  }, [dispatch, leadId]);

  const minor_color = `mb-2 ${styles.text_color}`;

  const EditVaraint = () => {
    setEdit(true);
  };

  const selectHandler = (value) => {
    setSelectedValue(value);
  };

  const submitHandler = () => {
    if (selectedValue) {
      dispatch(updateLead(lead.id, { status: selectedValue.value }));
      setEdit(false);
      setSelectedValue(null);
    }
  };

  return (
    <div className={styles.booking}>
      <h3 className={styles.heading}>Lead details</h3>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <div className={styles.container}>
          <div className={styles.bookingCard}>
            <div className={`${styles.box} ${styles.center}`}>
              <div>
                {/* <h1>Customer Details</h1> */}
                <p className={minor_color}>
                  Name:{" "}
                  <strong>
                    {lead.customer.first_name} {lead.customer.last_name}
                  </strong>
                </p>
                <p className={minor_color}>
                  Phone: <strong>{lead.customer.phone} </strong>
                </p>
                <p className={minor_color}>
                  Email: <strong>{lead.customer.email} </strong>
                </p>
                {lead?.customer?.current_location && (
                  <div className={`${minor_color} d-flex`}>
                    <p>Location: </p>
                    <div>
                      <strong>{lead.customer.current_location}</strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.box}>
              <h2 className={styles.heading}>{lead.model.name}</h2>

              <div className={styles.textcenter}>
                <p className={minor_color}>
                  {lead.variant?.name} - {lead.variant?.transmission_type}
                </p>

                <p className={minor_color}>
                  <Badge variant="primary p-3">
                    {Status.find((status) => status.value === lead.status).name}
                  </Badge>
                  <img
                    onClick={EditVaraint}
                    className={styles.editIcon}
                    src={Edit}
                    alt="editIcon"
                  />
                </p>
              </div>
            </div>
            <div className={`${styles.box} ${styles.center}`}>
              {lead.showroom ? (
                <p className={minor_color}>
                  Showroom : <strong> {lead.showroom.place}</strong>
                </p>
              ) : null}
              {lead.created_at ? (
                <p className={minor_color}>
                  Created on :{" "}
                  <strong>
                    {moment(new Date(lead.created_at)).format(
                      " h:mm a , MMM-D-YYYY"
                    )}
                  </strong>
                </p>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {edit && (
        <div className={styles.modal}>
          <span
            onClick={() => {
              setEdit(false);
              setSelectedValue(null);
            }}
            className={styles.btn}
          >
            &times;
          </span>
          <div className={styles.content}>
            <div className={styles.editItems}>
              {Status.map((stat) => (
                <p
                  className={
                    stat.value === lead.status
                      ? `${styles.items} ${styles.selected}`
                      : stat.value === selectedValue?.value
                      ? `${styles.items} ${styles.newSelected}`
                      : styles.items
                  }
                  key={stat.id}
                  onClick={() => selectHandler(stat)}
                >
                  {stat.name}
                </p>
              ))}
            </div>
            <div className="col text-center ">
              <Button
                onClick={submitHandler}
                variant="primary"
                disabled={!selectedValue}
                className={styles.button}
              >
                Change
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadDetails;
