import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectShowRoom,
  fetchShowRoom,
  selectIsLoading,
  setLoadingStatus,
  updateShowRoomDetails,
  selectIsUpateAPILoading,
} from "./showRoomSlice";
import { Container, Spinner, Row, Col, Button, Form } from "react-bootstrap";

import styles from "../models/Models.module.css";

const ShowRoom = ({ showroomId = "" }) => {
  const showRooms = useSelector(selectShowRoom);
  const isLoading = useSelector(selectIsLoading);
  const isUpateAPILoading = useSelector(selectIsUpateAPILoading);
  const dispatch = useDispatch();

  const [showRoomDetails, setshowRoomDetails] = useState({});
  const [editForm, setEditForm] = useState(false);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchShowRoom(showroomId));
  }, [dispatch, showroomId]);

  useEffect(() => {
    if (showRooms) {
      setshowRoomDetails(showRooms);
    }
  }, [showRooms]);

  const handleClick = () => {
    if (editForm) {
      dispatch(updateShowRoomDetails(showroomId, showRoomDetails));
      setEditForm(false);
    } else {
      setEditForm(true);
    }
  };

  const onChange = (value, showRoomContent) => {
    setshowRoomDetails({
      ...showRoomDetails,
      [showRoomContent]: value,
    });
  };
  const cancelHandler = () => {
    setEditForm(false);
    dispatch(fetchShowRoom(showroomId));
  };
  return (
    <Container className={styles.booking}>
      <h3 className="text-center">{showRooms.place} Showroom</h3>

      <br />
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        showRoomDetails && (
          <Form>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Place :
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  edit={editForm}
                  onChange={onChange}
                  value={showRoomDetails.place}
                  showRoomContent="place"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Address :
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  edit={editForm}
                  onChange={onChange}
                  value={showRoomDetails.address_line_1}
                  showRoomContent="address_line_1"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Address 2 :
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  edit={editForm}
                  onChange={onChange}
                  value={showRoomDetails.address_line_2}
                  showRoomContent="address_line_2"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                City :
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  edit={editForm}
                  onChange={onChange}
                  value={showRoomDetails.city}
                  showRoomContent="city"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                District :
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  edit={editForm}
                  onChange={onChange}
                  value={showRoomDetails.district}
                  showRoomContent="district"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Pin :
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  edit={editForm}
                  onChange={onChange}
                  value={showRoomDetails.pin}
                  showRoomContent="pin"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Contact No :
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  edit={editForm}
                  onChange={onChange}
                  value={showRoomDetails.contact_phone_1}
                  showRoomContent="contact_phone_1"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Contact No 2 :
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  edit={editForm}
                  onChange={onChange}
                  value={showRoomDetails.contact_phone_2}
                  showRoomContent="contact_phone_2"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Name :
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  edit={editForm}
                  onChange={onChange}
                  value={showRoomDetails.name}
                  showRoomContent="name"
                />
              </Col>
            </Form.Group>

            {/* <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Name:
              </Form.Label>
              <Col sm="9">
                <FormControl
                  className="font-weight-bold"
                  plaintext
                  readOnly
                  value={showRoomDetails.value}
                />
              </Col>
            </Form.Group> */}

            <Form.Group className={styles.buttons}>
              <Button
                variant="primary"
                size="sm"
                onClick={handleClick}
                disabled={isUpateAPILoading}
                // disabled={isLoading}
              >
                {isUpateAPILoading ? (
                  // {isLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : null}{" "}
                {editForm ? "Save changes" : "Edit Values"}
              </Button>{" "}
              {editForm ? (
                <Button variant="default" size="sm" onClick={cancelHandler}>
                  Cancel
                </Button>
              ) : null}
            </Form.Group>
          </Form>
        )
      )}
    </Container>
  );
};

const FormControl = ({ edit, value, showRoomContent, onChange }) => {
  return (
    <Form.Control
      className="font-weight-bold"
      plaintext={!edit}
      readOnly={!edit}
      value={value || ""}
      onChange={(e) => onChange(e.target.value, showRoomContent)}
    />
  );
};
export default ShowRoom;
