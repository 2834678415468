import { deleteApi, getApi, postApi, putApi } from "./utils";

export const getFinances = () => {
  return getApi("finance_mappings");
};

export const postFinance = (data) => {
  return postApi(`finance_mappings`, data);
};

export const getFinance = (id) => {
  return getApi(`finance_mappings/${id}`);
};

export const updateFinance = (id, data) => {
  return putApi(`finance_mappings/${id}`, data);
};

export const deleteFinance = (id) => {
  return deleteApi(`finance_mappings/${id}`);
};
