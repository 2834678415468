import { getApi, putApi } from "./utils";

export const getOfferVariants = (id) => {
  return getApi(`offers/${id}/variant_offers`);
};

export const getOfferVariant = (offerId, offerVariantId) => {
  return getApi(`offers/${offerId}/variant_offers/${offerVariantId}`);
};

export const putOfferVariant = (offerId, offerVariantId, data) => {
  return putApi(`offers/${offerId}/variant_offers/${offerVariantId}`, data);
};
