import React, { useEffect } from "react";
import {
  fetchfinanceLeads,
  selectIsLoading,
  selectfinanceLeads,
} from "./financeLeadSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "@reach/router";
import { Button, Container, Spinner, Table } from "react-bootstrap";
import { selectUserData } from "../login/loginSlice";

const FinanceLead = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const financeLeads = useSelector(selectfinanceLeads);
  const isfinanceLeadsLoading = useSelector(selectIsLoading);
  const userData = useSelector(selectUserData);

  useEffect(() => {
    dispatch(fetchfinanceLeads());
  }, [dispatch]);

  return (
    <Container>
      {isfinanceLeadsLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only text-center">Loading...</span>
        </Spinner>
      ) : financeLeads.length > 0 ? (
        <div>
          <h3 className="text-center">Your Finance Leads</h3>

          <Table className="text-center" responsive striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Model</th>
                <th>Customer</th>
                <th>Down Payment</th>
                <th>EMI</th>
                <th>LOAN</th>
                <th>EMI</th>
                <th>Tenure</th>

                {userData.user_type === "showroom_admin" && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {financeLeads.map((finance, index) => (
                <tr
                  key={finance.id}
                  // className={styles.finance}
                >
                  <td>{index + 1}</td>
                  <td>{finance.model.name || finance.model_id}</td>
                  <td>{finance.customer.first_name || finance.customer_id}</td>
                  <td>{finance.down_payment}</td>
                  <td>{finance.emi_approx}</td>
                  <td>{finance.status}</td>
                  <td>{finance.loan_amount}</td>
                  <td>{finance.tenure}</td>

                  {userData.user_type === "showroom_admin" && (
                    <th>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => navigate(`/financeLead/${finance.id}`)}
                      >
                        View more
                      </Button>
                    </th>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p>No Finance Leads found</p>
      )}
    </Container>
  );
};
export default FinanceLead;
