import React, { useState } from "react";
import { useEffect } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  postFinanceDetails,
  selectIsPostAPILoading,
  selectPostAPIError,
} from "./financeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "@reach/router";

const FinanceCreate = ({ ModelId }) => {
  const isLoading = useSelector(selectIsPostAPILoading);
  const financeCreateErrors = useSelector(selectPostAPIError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [postError, setPostError] = useState(false);
  const [errors, setErrors] = useState("");

  const [data, setData] = useState({
    model_id: ModelId,
    funding_percentage: "",
    funding_on: "",
    min_tenure: "",
    max_tenure: "",
    interest_from: "",
    interest_to: "",
    down_payment_starts: "",
    labels: "",
    delivery_time: "",
  });

  useEffect(() => {
    if (!ModelId) {
      setErrors("Model Id Error");
      return;
    }
  }, [ModelId]);

  useEffect(() => {
    if (!!financeCreateErrors.message) {
      setPostError(true);
      return;
    }
  }, [financeCreateErrors]);

  const submitHandler = () => {
    if (!data.model_id) {
      setShow(true);
      return;
    }
    dispatch(postFinanceDetails(data, navigate, ModelId));
  };

  return (
    <Container className="my-5">
      {errors.length > 0 ? (
        <p> {errors}</p>
      ) : (
        <>
          {show && (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>Complete the form before submitting the values</p>
            </Alert>
          )}{" "}
          {postError && (
            <Alert
              variant="danger"
              onClose={() => {
                setPostError(false);
              }}
              dismissible
            >
              <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
              <p>{financeCreateErrors.message}</p>
            </Alert>
          )}{" "}
          <h3 className="text-center">Create Finance </h3>
          <br />
          <Form>
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Funding Percentage :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  placeholder="Enter Funding Percentage"
                  value={data.funding_percentage}
                  onChange={(e) =>
                    setData({ ...data, funding_percentage: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Funding On :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  value={data.funding_on}
                  onChange={(e) =>
                    setData({
                      ...data,
                      funding_on: e.target.value,
                    })
                  }
                >
                  <option disabled value="">
                    Select Funding On
                  </option>
                  <option value="ex_showroom_price">Ex Showroom Price</option>
                  <option value="on_road_amount">On Road Amount </option>
                  <option value="on_road_amount_with_optional">
                    On Road Amount With Optional
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Min Tenure :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  placeholder="Enter Min Tenure"
                  value={data.min_tenure}
                  onChange={(e) =>
                    setData({ ...data, min_tenure: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Max Tenure :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  placeholder="Enter Max Tenure"
                  value={data.max_tenure}
                  onChange={(e) =>
                    setData({ ...data, max_tenure: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Interest From :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  placeholder="Enter Interest From"
                  value={data.interest_from}
                  onChange={(e) =>
                    setData({ ...data, interest_from: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Interest To :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  placeholder="Enter Interest To"
                  value={data.interest_to}
                  onChange={(e) =>
                    setData({ ...data, interest_to: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Down Payment Starts :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  placeholder="Enter Down Payment Starts"
                  value={data.down_payment_starts}
                  onChange={(e) =>
                    setData({ ...data, down_payment_starts: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Delivery Time :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  placeholder="Enter Delivery Time"
                  value={data.delivery_time}
                  onChange={(e) =>
                    setData({ ...data, delivery_time: e.target.value })
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Label :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  placeholder="Enter Label"
                  value={data.labels}
                  onChange={(e) => setData({ ...data, labels: e.target.value })}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Model Id :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="font-weight-bold"
                  disabled
                  value={ModelId}
                />
              </Col>
            </Form.Group>
            <div className="col text-center mt-5">
              <Button variant="primary" onClick={submitHandler}>
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Container>
  );
};

export default FinanceCreate;
