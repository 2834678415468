import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchModels,
  selectIsLoading,
  selectModels,
  setLoadingStatus,
} from "./modelsSlice";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import styles from "./Models.module.css";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "@reach/router";
import { BodyType } from "../data";
import Image from "../../atoms/Images/Image";
import { selectUserData } from "../login/loginSlice";
const Models = ({ bookingId = "" }) => {
  const models = useSelector(selectModels);
  const isLoading = useSelector(selectIsLoading);
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchModels());
  }, [bookingId, dispatch]);
  const zoom = (src) => {
    setImage(src);
    setShow(true);
  };
  return (
    <>
      <Container className={styles.home}>
        <h3 className="text-center">Your models</h3>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : models.length > 0 ? (
          <Table className="text-center" responsive striped bordered hover>
            <thead>
              <tr>
                <th>Model Name</th>
                <th>Image</th>
                <th>Body Type</th>
                <th>Starting Price</th>
                <th>Ending Price</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {models.map((model) => (
                <tr key={model.id} className={styles.model}>
                  <td>{model.name}</td>
                  <td
                    className={styles.imageCol}
                    onClick={() => zoom(model.main_image)}
                  >
                    <Image src={model.main_image} />
                  </td>
                  <td>{BodyType[model.body_type]}</td>
                  <td>{model.price_starts}</td>
                  <td>{model.price_ends}</td>
                  <th>
                    <div className={styles.actions}>
                      {userData.user_type === "dealer_admin" && (
                        <>
                          <Button
                            onClick={() =>
                              navigate(`/create-offer/Model/${model.id}`)
                            }
                            variant="outline-success"
                            size="sm"
                          >
                            Create Offer
                          </Button>
                          <Button
                            onClick={() =>
                              navigate(`/create-finance/Model/${model.id}`)
                            }
                            variant="outline-info"
                          >
                            Create Finance
                          </Button>
                        </>
                      )}
                      <Button
                        onClick={() => navigate(`/models/${model.id}`)}
                        variant="outline-primary"
                        size="sm"
                      >
                        View More
                      </Button>
                    </div>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>
            No models found in your system. Contact Carbae admin for details
          </p>
        )}
      </Container>

      {show && (
        <div className={styles.modal} onClick={() => setShow(false)}>
          <span onClick={() => setShow(false)} className={styles.btn}>
            &times;
          </span>
          <div className={styles.content}>
            <Image src={image && image} className={styles.img} />
          </div>
        </div>
      )}
    </>
  );
};
export default Models;
