export const FuelType = {
  both_diesel_and_petrol: "Both",
  diesel: "Diesel",
  petrol: "Petrol",
  cng: "CNG",
  electric: "Electric",
};
export const TransmissionType = {
  both: "Both",
  manual: "Manual",
  automatic: "Automatic",
};
export const BodyType = {
  suv: "SUV",
  hatchback: "HatchBack",
  sedan: "Sedan",
  minivan: "Minivan",
  muv: "MUV",
  cuv: "CUV",
};
export const Status = [
  { name: "Open", value: "open", id: 1 },
  { name: "Contacted", value: "contacted", id: 2 },
  { name: "Interested", value: "interested", id: 3 },
  { name: "Not Interested", value: "not_interested", id: 4 },
  { name: "Booked", value: "booked", id: 5 },
  { name: "Finance Processing", value: "finance_processing", id: 6 },
  { name: "Delivery Scheduled", value: "delivery_scheduled", id: 7 },
  { name: "Delivered", value: "delivered", id: 8 },
  { name: "Closed", value: "closed", id: 9 },
  { name: "Test Drive Scheduled", value: "test_drive_scheduled", id: 10 },
  { name: "Test Drive Done", value: "test_drive_done", id: 11 },
];
export const FinanceStatus = [
  { name: "Open", value: "open", id: 1 },
  { name: "Processing", value: "processing", id: 2 },
  { name: "Passed", value: "passed", id: 3 },
  { name: "Rejected", value: "rejected", id: 4 },
  { name: "Closed", value: "closed", id: 5 },
];
