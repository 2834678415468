import React from "react";
import styles from "./styles.module.css";

const Image = ({ src, alt, ...props }) => {
  const SERVER_HOST = process.env.REACT_APP_API_BASE_ENDPOINT;
  return (
    <div className={styles.imgWrapper}>
      <img src={`${SERVER_HOST}${src}`} {...props} alt={alt} />
    </div>
  );
};

export default Image;
