import { createSlice } from "@reduxjs/toolkit";
import { getBookings } from "../../api/bookings";

export const slice = createSlice({
  name: "bookings",
  initialState: {
    bookings: [],
    loadingBookings: true,
    error: {
      message: "",
    },
  },
  reducers: {
    getBookingsSuccess: (state, action) => {
      state.bookings = action.payload;
      state.loadingBookings = false;
      state.error = {};
    },
    getBookingsFailed: (state) => {
      state.bookings = [];
      state.loadingBookings = false;
      state.error.message = "Something went wrong";
    },

    clearBookings: (state) => {
      state.bookings = [];
      state.loadingBookings = true;
      state.error = {
        message: "",
      };
    },
  },
});

export const selectBookings = (state) => state.bookings.bookings || [];
export const selectIsLoading = (state) => state.bookings.loadingBookings;

export const { getBookingsSuccess, getBookingsFailed, clearBookings } =
  slice.actions;
export const fetchBookings = () => async (dispatch) => {
  try {
    const bookingsDetails = await getBookings();
    dispatch(getBookingsSuccess(bookingsDetails.data));
  } catch (err) {
    dispatch(getBookingsFailed(err.toString()));
  }
};

export default slice.reducer;
