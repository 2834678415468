import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTestDrive,
  selectTestDrive,
  selectIsLoading,
} from "./testDriveDetailsSlice";
import Image from "../../atoms/Images/Image";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import styles from "./Testdrive.module.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
const TestDriveDetails = ({ testdriveId = "" }) => {
  const testdrive = useSelector(selectTestDrive);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTestDrive(testdriveId));
  }, [testdriveId, dispatch]);
  const minor_color = `mb-2 ${styles.text_color}`;

  return (
    <div className={styles.booking}>
      <h3 className={styles.heading}>Test Drive details</h3>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <div className={styles.bookingCard}>
          <div className={`${styles.detailBox} ${styles.model}`}>
            <div className={styles.bookingData}>
              <div className={styles.user}>
                {/* <h2 className={styles.heading}>Customer Details</h2> */}
                <p className={minor_color}>
                  Name
                  <span className={styles.userhalf}>
                    : {testdrive.customer.first_name}{" "}
                    {testdrive.customer.last_name}
                  </span>
                </p>
                <p className={minor_color}>
                  Phone
                  <span className={styles.userhalf}>
                    : {testdrive.customer.phone}{" "}
                  </span>
                </p>
                <p className={minor_color}>
                  Email
                  <span className={styles.userhalf}>
                    : {testdrive.customer.email}{" "}
                  </span>
                </p>
                {testdrive?.customer?.current_location && (
                  <div className={`${minor_color} d-flex`}>
                    <p>Location: </p>
                    <div>
                      <strong>{testdrive.customer.current_location}</strong>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.bookingData}>
              {" "}
              <h2 className={styles.heading}>{testdrive.model.name}</h2>
              <Image src={testdrive.model.main_image} className={styles.img} />
              <div className={styles.showroomdetails}>
                <p className={minor_color}>
                  Place Selected{" "}
                  <span className={styles.half}> : {testdrive.place}</span>
                </p>
                {testdrive.showroom ? (
                  <p className={minor_color}>
                    Showroom{" "}
                    <span className={styles.half}>
                      : {testdrive.showroom.place}
                    </span>
                  </p>
                ) : null}
                {testdrive.created_at ? (
                  <p className={minor_color}>
                    Booked on{" "}
                    <span className={styles.half}>
                      {" "}
                      :{" "}
                      {moment(new Date(testdrive.created_at)).format(
                        " h:mm a , MMM-D-YYYY"
                      )}
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
            <div className={styles.bookingData}>
              <p className={styles.selecteddate}> Test Drive Requested Date</p>
              <p className={styles.date}>
                {testdrive.date && new Date(testdrive.date).toDateString()}
              </p>
              <div className={styles.selecteddate}>
                <DatePicker
                  selected={new Date(testdrive.date)}
                  // includeDateIntervals={[
                  //   {
                  //     start: new Date(testdrive.date),
                  //     end: new Date(testdrive.date),
                  //   },
                  // ]}
                  minDate={new Date(testdrive.date)}
                  maxDate={new Date(testdrive.date)}
                  inline
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default TestDriveDetails;
