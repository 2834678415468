import React, { useEffect } from "react";
import {
  fetchModelOffers,
  selectIsOffersLoading,
  selectOffers,
} from "../../features/models/modelsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "@reach/router";
import { Button, Spinner, Table } from "react-bootstrap";
// import styles from "./offer.module.css";
import { selectUserData } from "../../features/login/loginSlice";

const OfferList = ({ modelId = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const offers = useSelector(selectOffers);
  const isOffersLoading = useSelector(selectIsOffersLoading);
  const userData = useSelector(selectUserData);

  useEffect(() => {
    dispatch(fetchModelOffers(modelId));
  }, [modelId, dispatch]);

  return (
    <>
      <div className="my-3 d-grid gap-2 d-md-flex justify-content-md-end">
        {userData.user_type === "dealer_admin" && (
          <Button
            onClick={() => navigate(`/create-offer/Model/${modelId}`)}
            variant="primary"
          >
            Create Offer
          </Button>
        )}
      </div>
      {isOffersLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only text-center">Loading...</span>
        </Spinner>
      ) : offers.length > 0 ? (
        <div>
          <h3 className="text-center">Your Offers</h3>
          <h3 className="text-right text-primary">
            Total Active Offer Amount :{" "}
            <b>
              {offers.reduce((total, item) => {
                if (item.is_active && item.valuation === "flat") {
                  total += item.amount;
                }
                return total;
              }, 0)}
            </b>
          </h3>
          <Table className="text-center" responsive striped bordered hover>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Applicable Type</th>
                <th>Applicable Name</th>
                <th>Offer Type</th>
                <th>Status</th>
                <th>Offer Amount</th>
                {userData.user_type === "dealer_admin" && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {offers.map((offer, index) => (
                <tr
                  key={offer.id}
                  // className={styles.offer}
                >
                  <td>{index + 1}</td>
                  <td>{offer.name}</td>
                  <td>{offer.applicable_type}</td>
                  <td>{offer.applicable.name}</td>
                  <td>{offer.offer_type.name}</td>
                  <td>{offer.is_active ? "Active" : "Not Active"}</td>
                  <td>
                    {offer.amount}
                    {offer.valuation === "flat" ? " Rs " : " % "}
                  </td>
                  {userData.user_type === "dealer_admin" && (
                    <th>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => navigate(`/offers/${offer.id}`)}
                      >
                        View more
                      </Button>
                    </th>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p>No offers found</p>
      )}
    </>
  );
};

export default OfferList;
