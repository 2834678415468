import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectofferType,
  fetchofferType,
  selectIsLoading,
  setLoadingStatus,
  updateofferTypeDetails,
  selectIsUpateAPILoading,
  deleteofferTypeDetails,
} from "./offerTypeSlice";
import { Container, Spinner, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "@reach/router";

const OfferTypeDetail = ({ offertypeId = "" }) => {
  const offerType = useSelector(selectofferType);
  const isLoading = useSelector(selectIsLoading);
  const isUpateAPILoading = useSelector(selectIsUpateAPILoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [offerTypeDetails, setofferTypeDetails] = useState({});
  const [editForm, setEditForm] = useState(false);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchofferType(offertypeId));
  }, [dispatch, offertypeId]);

  useEffect(() => {
    if (offerType) {
      setofferTypeDetails(offerType);
    }
  }, [offerType]);

  const handleClick = () => {
    if (editForm) {
      dispatch(updateofferTypeDetails(offertypeId, offerTypeDetails));
      setEditForm(false);
    } else {
      setEditForm(true);
    }
  };

  const onChange = (value, offerTypeContent) => {
    setofferTypeDetails({
      ...offerTypeDetails,
      [offerTypeContent]: value,
    });
  };
  const cancelHandler = () => {
    setEditForm(false);
    dispatch(fetchofferType(offertypeId));
  };
  const deleteHandler = (id) => {
    dispatch(deleteofferTypeDetails(id, navigate));
  };
  return (
    <Container
    //  className={styles.booking}
    >
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        offerTypeDetails && (
          <>
            <h3 className="text-center">{offerType.name} Offer Type</h3>
            <br />
            <Form>
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Name :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.name}
                    offerTypeContent="name"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Description :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.description}
                    offerTypeContent="description"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Condition :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.condition}
                    offerTypeContent="condition"
                  />
                </Col>
              </Form.Group>

              <Form.Group
              // className={styles.buttons}
              >
                <Button
                  variant="success"
                  size="sm"
                  onClick={handleClick}
                  disabled={isUpateAPILoading}
                  // disabled={isLoading}
                >
                  {isUpateAPILoading ? (
                    // {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : null}{" "}
                  {editForm ? "Save changes" : "Edit Values"}
                </Button>{" "}
                {editForm ? (
                  <Button variant="default" size="sm" onClick={cancelHandler}>
                    Cancel
                  </Button>
                ) : null}
                {!editForm ? (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => deleteHandler(offerTypeDetails.id)}
                    disabled={isUpateAPILoading}
                    // disabled={isLoading}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </Form.Group>
            </Form>
          </>
        )
      )}
    </Container>
  );
};

const FormControl = ({ edit, value, offerTypeContent, onChange }) => {
  return (
    <Form.Control
      className="font-weight-bold"
      plaintext={!edit}
      readOnly={!edit}
      value={value || ""}
      onChange={(e) => onChange(e.target.value, offerTypeContent)}
    />
  );
};

export default OfferTypeDetail;
