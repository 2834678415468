import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useNavigate } from "@reach/router";
import { postofferTypeDetails, selectIsPostAPILoading } from "./offerTypeSlice";
import { Alert, Col, Row } from "react-bootstrap";
import { useState } from "react";

const OfferTypeCreateForm = () => {
  const isLoading = useSelector(selectIsPostAPILoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [condition, setCondition] = useState("");
  const [show, setShow] = useState(false);

  const submitHandler = () => {
    // validate
    if (!name.length || !description.length || !condition.length) {
      setShow(true);
      return;
    }
    const payload = { name, description, condition };
    dispatch(postofferTypeDetails(payload, navigate));
    // if succes navigate to list page
  };
  return (
    <>
      {show && (
        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>Complete the form before submitting the values</p>
        </Alert>
      )}
      <Container className="my-5">
        <h3 className="text-center">Create Offer Type</h3>

        <br />
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Name :
            </Form.Label>
            <Col sm="9">
              <Form.Control
                className="font-weight-bold"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Description :
            </Form.Label>
            <Col sm="9">
              <Form.Control
                className="font-weight-bold"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Condition :
            </Form.Label>
            <Col sm="9">
              <Form.Control
                className="font-weight-bold"
                placeholder="Enter Condition"
                value={condition}
                onChange={(e) => setCondition(e.target.value)}
              />
            </Col>
          </Form.Group>
          <div className="col text-center mt-5">
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={submitHandler}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default OfferTypeCreateForm;
