import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectfinance,
  fetchfinance,
  selectIsLoading,
  setLoadingStatus,
  updateFinanceDetails,
  selectIsUpateAPILoading,
  deleteFinanceDetails,
} from "./financeSlice";
import { Container, Spinner, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate } from "@reach/router";

const FinanceDetails = ({ financeId = "" }) => {
  const offerType = useSelector(selectfinance);
  const isLoading = useSelector(selectIsLoading);
  const isUpateAPILoading = useSelector(selectIsUpateAPILoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [offerTypeDetails, setofferTypeDetails] = useState({});
  const [editForm, setEditForm] = useState(false);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchfinance(financeId));
  }, [dispatch, financeId]);

  useEffect(() => {
    if (offerType) {
      setofferTypeDetails(offerType);
    }
  }, [offerType]);

  const handleClick = () => {
    if (editForm) {
      dispatch(updateFinanceDetails(financeId, offerTypeDetails));
      setEditForm(false);
    } else {
      setEditForm(true);
    }
  };

  const onChange = (value, offerTypeContent) => {
    setofferTypeDetails({
      ...offerTypeDetails,
      [offerTypeContent]: value,
    });
  };
  const cancelHandler = () => {
    setEditForm(false);
    dispatch(fetchfinance(financeId));
  };
  const deleteHandler = (id) => {
    dispatch(deleteFinanceDetails(id, navigate));
  };
  return (
    <Container
    //  className={styles.booking}
    >
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        offerTypeDetails && (
          <>
            <h3 className="text-center">{offerType.name} Offer Type</h3>
            <br />

            <Form>
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Model :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    value={offerTypeDetails?.model?.name}
                    offerTypeContent="name"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Funding Percentage :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.funding_percentage}
                    offerTypeContent="funding_percentage"
                  />
                </Col>
              </Form.Group>
              {!editForm ? (
                <Form.Group as={Row} controlId="formPlaintextEmail">
                  <Form.Label column sm="3">
                    Funding On :
                  </Form.Label>
                  <Col sm="9">
                    <FormControl
                      className="font-weight-bold"
                      edit={editForm}
                      onChange={onChange}
                      value={offerTypeDetails.funding_on}
                      offerTypeContent="funding_on"
                    />
                  </Col>
                </Form.Group>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Funding On :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="select"
                      value={offerTypeDetails.funding_on}
                      onChange={(e) =>
                        setofferTypeDetails({
                          ...offerTypeDetails,
                          funding_on: e.target.value,
                        })
                      }
                    >
                      <option disabled value="">
                        Select Funding On
                      </option>
                      <option value="ex_showroom_price">
                        Ex Showroom Price
                      </option>
                      <option value="on_road_amount">On Road Amount </option>
                      <option value="on_road_amount_with_optional">
                        On Road Amount With Optional
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Min Tenure :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.min_tenure}
                    offerTypeContent="min_tenure"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Max Tenure :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.max_tenure}
                    offerTypeContent="max_tenure"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Interest From :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.interest_from}
                    offerTypeContent="interest_from"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Intetrest To :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.interest_to}
                    offerTypeContent="interest_to"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Down Payment Starts :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.down_payment_starts}
                    offerTypeContent="down_payment_starts"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Delivery Time :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.delivery_time}
                    offerTypeContent="delivery_time"
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextEmail">
                <Form.Label column sm="3">
                  Labels :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={offerTypeDetails.labels}
                    offerTypeContent="labels"
                  />
                </Col>
              </Form.Group>

              <Form.Group
              // className={styles.buttons}
              >
                <Button
                  variant="success"
                  size="sm"
                  onClick={handleClick}
                  disabled={isUpateAPILoading}
                  // disabled={isLoading}
                >
                  {isUpateAPILoading ? (
                    // {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : null}{" "}
                  {editForm ? "Save changes" : "Edit Values"}
                </Button>{" "}
                {editForm ? (
                  <Button variant="default" size="sm" onClick={cancelHandler}>
                    Cancel
                  </Button>
                ) : null}
                {!editForm ? (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => deleteHandler(offerTypeDetails.id)}
                    disabled={isUpateAPILoading}
                    // disabled={isLoading}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </Form.Group>
            </Form>
          </>
        )
      )}
    </Container>
  );
};

export default FinanceDetails;

const FormControl = ({ edit, value, offerTypeContent, onChange }) => {
  return (
    <Form.Control
      className="font-weight-bold"
      plaintext={!edit}
      readOnly={!edit}
      value={value || ""}
      onChange={(e) => onChange(e.target.value, offerTypeContent)}
    />
  );
};
