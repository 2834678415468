import React from "react";
import Container from "react-bootstrap/Container";
import styles from "./privacy.module.css";

const PrivacyPolicy = () => (
  <Container className={styles.term}>
    <br />
    <h1 className="text-center">Privacy Policy </h1>
    <br />

    <div className={styles.font}>
      <p>
        CarBae accessible at Carbae.in, One of our main priorities is the
        privacy of our visitors. This Privacy Policy document contains types of
        information that is collected and recorded by CarBae and how we use it.
      </p>
      <p>
        This Privacy Policy applies to Carbae and help you understand how we
        collect, use, store, process, transfer, share, and otherwise deal with
        and protect all your information when you visit any of our Platform and
        use our products and services or even visit this website.
      </p>
    </div>

    <h2>Consent</h2>
    <div className={styles.font}>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
    </div>

    <h2>Acknowledgment:</h2>
    <div className={styles.font}>
      <p>
        PLEASE READ THIS PRIVACY POLICY CAREFULLY. YOU INDICATE THAT YOU
        UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY POLICY. HENCE BY VISITING
        OUR PLATFORM OR BY USING / AVAILING ANY OF OUR SERVICE OR PRODUCT, YOU
        HEREBY GIVE YOUR UNCONDITIONAL CONSENT OR AGREEMENT TO AUTOBAE
        TECHNOLOGIES PRIVATE LIMITED AS PROVIDED UNDER SECTION 43A AND SECTION
        72A OF INDIAN INFORMATION TECHNOLOGY ACT, 2000 FOR COLLECTION, USE,
        STORAGE, PROCESSING, SHARING AND TRANSFER AND DISCLOSURE OF YOUR
        INFORMATION.
      </p>
      <p>
        YOU ACKNOWLEDGE THAT YOU HAVE ALL LEGAL RIGHTS AND LAWFUL AUTHORITY TO
        SHARE THE INFORMATION WITH US AND FURTHER ACKNOWLEDGE THAT BY
        COLLECTING, SHARING, PROCESSING AND TRANSFERRING INFORMATION PROVIDED BY
        YOU, SHALL NOT CAUSE ANY LOSS OR WRONFUL GAIN TO YOU OR ANY OTHER
        PERSON.
      </p>
      <p>
        IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT
        ACCESS / USE OUR PLATFORM OR AVAIL ANY OF OUR SERVICE OR PRODUCT ON OUR
        PLATFORM.
      </p>
    </div>

    <p>This policy is designed to make you understand:</p>
    <ol className={styles.list}>
      <li>
        The type of information which You share with Us or which We collect
        during Your usage of our Platform or availing any Product or Services
        from Our Platform
      </li>
      <li>
        The purpose of collection, storage, processing and transferring of Your
        Information by Us.
      </li>
      <li>
        Security measures implemented by Us to protect Your Information as
        mandated by law.
      </li>
      <li>
        Disclosure, sharing and transfer of Your personal information by Us and
        purpose of such disclosure, sharing or transfer
      </li>
    </ol>

    <h2>Information We Collect (Your Information):</h2>
    <p>
      We collect Your information during Your usage of Platform or when You
      avail any of products or services available on or through Platform, either
      as a registered user or otherwise when you visit any of our other website
      pages or mobile applications.
    </p>
    <p>The information collected may consist of:</p>
    <ol>
      <li>Your Personal Information:</li>
      <br />
      <p>
        Your full name, age, address, email id, phone number, date of birth,
        gender, financial information, any other sensitive personal data or
        information etc. We collect Your Personal Information when You create an
        account at Our Platform or fill out a form or respond to a survey.
      </p>
      <li>Your Non-Personal information: </li>
      <br />
      <p>
        a) Usage : How You use Our Platform, such as the types of content that
        you view or engage with, the features You use, Your searches & results,
        Your browsing information, the actions You take and the time, frequency
        and duration of your activities.
      </p>
      <p>
        b) Device Information: Information about the computer, mobile, laptop,
        tablet or any other internet enabled electronic device (”Device”) You
        use to access the Platform. This may include real-time information about
        the geographic location of Your Device as permitted by You, internet
        connection, Your IP address, operating systems, platforms, browsing
        information, Device type, Device ID, network information, metadata and
        other information associated with other files stored on Your Device,
        last URL visited, your website search history.
      </p>
      <p>
        c) Preference: Your preferences and setting such as geographical
        location, time zone and language.
      </p>
      <p>
        d) Information from third parties: We may collect, process and store
        Your user ID associated with any social media account (such as your
        Facebook and Google account) that You use to sign into the Platform or
        connect with or use with the products or services. When You sign in to
        Your account with Your social media account information, or otherwise
        connect to Your social media account with the Product or Services, You
        consent to Our collection, storage, and use, in accordance with this
        Privacy Policy, of the information that You make available to Us through
        the social media interface. This could include, without limitation, any
        information that You have made public through Your social media account,
        information that the social media service shares with Us, or information
        that is disclosed during the sign-in process. Please see Your social
        media provider's privacy policy and help center for more information
        about how they share information when You choose to connect Your
        account.
      </p>
      <p>
        We may also obtain information about You from third parties such as
        partners, marketers, third-party websites and researchers and combine
        that information with information which We collect from or about You.
      </p>
      <p>
        We may use third-party service providers to serve ads on CarBae’s behalf
        across the internet and sometimes on the Platform. They may collect
        information about Your visits to the Platform, and Your interaction with
        the products and services on the Platform.
      </p>
      <p>
        e) Cookies and other Electronic Tools: We may use cookies, pixel tags,
        web beacons, mobile device IDs and similar files or technologies to
        collect and store information in respect to Your use of the Platform1.
        You can erase or choose to disable/block these cookies through Your
        Device/browser settings. You can configure Your browser to alert You
        when we attempt to send You a cookie with an option to accept or refuse
        the cookie. If You have turned cookies off, You may be prevented from
        using certain features of the Platform and this may interfere with
        certain functionality of the Platform.
      </p>
    </ol>

    <h2>Purpose of Collection, Storage, Processing of Your Information:</h2>
    <p>
      CarBae collect Your Information solely for the purpose of providing you
      the products or services that is connected with the function or activity
      of the CarBae which includes but not limited to the following (“Purpose”):
    </p>
    <ul>
      <li>
        To inform You about various products and services offered on Our
        Platform and provide customer support to You.
      </li>
      <li>
        To address Your queries and resolve your concerns pertaining to any
        product or service that You were looking for.
      </li>
      <li>
        To send You survey and marketing communications or facilitate various
        programmes and initiatives launched either by Us or third party which We
        believe may be of interest to You.
      </li>
      <li>
        To facilitate Your usage of Platform (what features You like, how You
        use the, etc.) and improve Our products or services, or any other
        content on the Platform (such as by personalizing content to your
        interests), process and complete your transactions, and make special
        offers
      </li>
      <li>
        To review Platform performance, diagnose technical problems and do data
        analysis to make Your experience better.
      </li>
      <li>To protect the integrity of Our Platform</li>
      <li>
        To inform You about change in any term and condition of this Privacy
        Policy or Terms of Use of any Platform of CarBae
      </li>
      <li>
        To implement information security practices, to determine any security
        breaches, contaminant or computer virus, to investigate/prevent/take
        action against illegal activities and suspected fraud.
      </li>
      <li>
        To keep You informed about news, events and ideas regarding Your
        preferences and help You to improve Your knowledge and skills about the
        preferred content.
      </li>
      <li>
        To carry out Our obligations and enforce Our rights arising from any
        contracts entered into between You and Us.
      </li>
      <li>
        To allow You to participate in interactive features offered through Our
        Platform.
      </li>
      <li>
        To contact You about Our own and third-party products and services that
        may be of interest to You. If You do not want Us to use Your Information
        in this way, please check the relevant box located on the form on which
        We collect Your data and/or adjust Your user preferences in Your account
        profile.
      </li>
      <li>
        To produce and share aggregate insights and statistics that do not
        identify You.
      </li>
      <li>
        To enable Us independently or in association with third party to show
        You tailored ads that are relevant to You.
      </li>
      <li>
        To enable Us to display advertisements to our advertisers'/service
        providers' target audiences. Even though We do not disclose Your
        Personal Information for these purposes without Your consent, if You
        click on or otherwise interact with an advertisement, the advertiser may
        assume that You meet its target criteria.
      </li>
      <li>
        To help advertisers and other partners measure the effectiveness and
        distribution of their ads and services, and understand the types of
        people who use their services and how people interact with their
        websites, apps and services.
      </li>
      <li>
        In any other way We may describe when You provide the Information.
      </li>
      <li>For any other purpose with Your consent</li>
    </ul>

    <h2>Sharing, Transfer or Disclosure of Your Information:</h2>
    <ol>
      <li>
        Any service or product provided through Our Platform may be provided
        either by CarBae or its vendor, dealer, OEM, channel partners and other
        third parties (“Other Entities”) which are registered with CarBae to
        render the services or provide products through Platform or otherwise.
        You acknowledge and agree that CarBae may share, disclose, transfer or
        part with Your Information to Other Entities depending upon the type of
        Product or Service You are looking for or for one or more of the
        permissible purposes. CarBae may also share Your Personal or
        Non-Personal information where it is required to share the same with
        such Other Entities for providing you the product or service and You
        provide CarBae Your unconditional consent to do the same.
      </li>
      <li>
        CarBae may share statistical data and/or other Non-personal Information
        or details to facilitate various program or initiatives launched by
        CarBae, third party service providers, partners or financial
        institutions, from time to time.
      </li>
      <li>
        We use other third parties such as a credit/debit card processing
        company, payment gateway, pre-paid cards etc. to enable You to make
        payments for buying products or availing services on Platform. When You
        sign up for these services, You may have the ability to save Your card
        details for future reference and faster future payments. In such case,
        We may share Your relevant Information as necessary for the third
        parties to provide such services, including your name, residence and
        email address. The processing of payments or authorization is solely in
        accordance with these third parties policies, terms and conditions and
        we are not in any manner responsible or liable to You or any third party
        for any delay or failure at their end in processing the payments.
      </li>
      <li>
        In addition to this, CarBae reserve the right to share Your Information
        with any Government Agency or other authorized law enforcement agencies
        (LEAs) mandated under law to obtain Your Information for the purpose of
        verification of identity or for prevention, detection, investigation
        including but not limited to cyber incidents, prosecution, and
        punishment of offences etc.
      </li>
      <li>
        We may process Your Information outside India ad relay on legally
        provided mechanisms to lawfully transfer Your Information across
        borders.
      </li>
      <li>
        We may share Your Information in any other circumstances where We have
        Your consent.
      </li>
    </ol>

    <h2>Change in terms of Privacy Policy:</h2>
    <p>
      CarBae reserves the right to amend or modify this Privacy Policy at any
      time, as and when the need arises. We request you to regularly check this
      Privacy Policy from time to time to keep you apprise of changes made. Your
      continued use of the Platform gives your unconditional acceptance to such
      change in terms of Privacy Policy.
    </p>
    <p>
      Particulars of corporate entity, Data Protection Officer/Grievance Officer
      pursuant to the provisions of Companies Act, 2013 and The Indian
      Information Technology Act, 2000 and rules made and applicable laws
      thereunder
    </p>
  </Container>
);
export default PrivacyPolicy;
