import { createSlice } from "@reduxjs/toolkit";
import {
  deleteOffer,
  putOffer,
  getOffer,
  postOffer,
  getOffers,
  putBulkOffer,
} from "../../api/offers";

export const slice = createSlice({
  name: "offer",
  initialState: {
    offers: [],
    offer: {},
    loading: true,
    waitingForUpdateApi: false,
    waitingForPostApi: false,
    waitForBulkUpdate: false,
    error: {
      message: "",
    },
  },
  reducers: {
    getoffersSuccess: (state, action) => {
      state.offers = action.payload;
      state.loading = false;
      state.error = {};
    },
    getofferSuccess: (state, action) => {
      state.offer = action.payload;
      state.loading = false;
      state.error = {};
    },

    getoffersFailed: (state) => {
      state.offers = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    getofferFailed: (state) => {
      state.offer = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    postofferFailed: (state, action) => {
      state.offer = [];
      state.waitingForPostApi = false;
      state.error.message = action.payload;
    },

    setLoading: (state) => {
      state.loading = true;
    },
    resetError: (state) => {
      state.error = {};
    },
    setLoadingUpdate: (state, action) => {
      state.waitingForUpdateApi = action.payload;
    },
    setBulkLoadingUpdate: (state, action) => {
      state.waitForBulkUpdate = action.payload;
    },
    setLoadingPost: (state, action) => {
      state.waitingForPostApi = action.payload;
    },
  },
});

export const selectoffer = (state) => state.offer.offer || [];
export const selectoffers = (state) => state.offer.offers || [];
export const selectIsLoading = (state) => state.offer.loading;
export const selectIsUpateAPILoading = (state) =>
  state.offer.waitingForUpdateApi;
export const selectIsBulkUpateAPILoading = (state) =>
  state.offer.waitForBulkUpdate;
export const selectIsPostAPILoading = (state) => state.offer.waitingForPostApi;
export const selectPostAPIError = (state) => state.offer.error;

export const {
  getoffersSuccess,
  getofferSuccess,
  setLoading,
  getofferFailed,
  getoffersFailed,
  postofferFailed,
  resetError,
  setLoadingUpdate,
  setBulkLoadingUpdate,
  setLoadingPost,
} = slice.actions;
export const setLoadingStatus = (loading) => (dispatch) => {
  dispatch(setLoading(loading));
};
export const fetchoffers = () => async (dispatch) => {
  try {
    setLoadingStatus(true);
    const offers = await getOffers();
    dispatch(getoffersSuccess(offers.data));
  } catch (err) {
    dispatch(getoffersFailed(err.toString()));
  }
};

export const fetchoffer = (id) => async (dispatch) => {
  try {
    const offer = await getOffer(id);
    dispatch(getofferSuccess(offer.data));
  } catch (err) {
    dispatch(getofferFailed(err.toString()));
  }
};

export const postofferDetails = (payload, navigate) => async (dispatch) => {
  try {
    dispatch(setLoadingPost(true));
    const response = await postOffer(payload);
    if (response.status === "error") {
      throw new Error(response.message);
    }
    dispatch(setLoadingPost(false));
    const { applicable_type, applicable_id } = payload;
    if (applicable_type === "Model") {
      navigate(`/models/${applicable_id}#offers`);
      return;
    }
    navigate("/offers");
  } catch (err) {
    dispatch(postofferFailed(err.toString()));
  }
};
export const updateofferDetails = (Id, payload) => async (dispatch) => {
  try {
    dispatch(setLoadingUpdate(true));
    const offer = await putOffer(Id, payload);
    dispatch(getofferSuccess(offer.data));
    dispatch(setLoadingUpdate(false));
  } catch (err) {
    dispatch(getofferFailed(err.toString()));
  }
};

export const updateBulkoffer = (Id, payload) => async (dispatch) => {
  try {
    dispatch(setBulkLoadingUpdate(true));
    await putBulkOffer(Id, payload);
    dispatch(fetchoffer(Id));
    dispatch(setBulkLoadingUpdate(false));
  } catch (err) {
    dispatch(getofferFailed(err.toString()));
  }
};

export const deleteofferDetails = (Id, navigate) => async (dispatch) => {
  try {
    await deleteOffer(Id);
    navigate("/offers");
  } catch (err) {
    dispatch(getofferFailed(err.toString()));
  }
};

export default slice.reducer;
