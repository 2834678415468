import { createSlice } from "@reduxjs/toolkit";
import {
  putOfferVariant,
  getOfferVariant,
  getOfferVariants,
} from "../../api/offerVaraint";

export const slice = createSlice({
  name: "offerVariant",
  initialState: {
    offerVariants: [],
    offerVariant: {},
    loading: true,
    waitingForUpdateApi: false,
    error: {
      message: "",
    },
  },
  reducers: {
    getofferVariantsSuccess: (state, action) => {
      state.offerVariants = action.payload;
      state.loading = false;
      state.error = {};
    },
    getofferVariantSuccess: (state, action) => {
      state.offerVariant = action.payload;
      state.loading = false;
      state.error = {};
    },

    getofferVariantsFailed: (state) => {
      state.offerVariants = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    getofferVariantFailed: (state) => {
      state.offerVariant = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },

    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingUpdate: (state, action) => {
      state.waitingForUpdateApi = action.payload;
    },
  },
});

export const selectofferVariant = (state) =>
  state.offerVariant.offerVariant || [];
export const selectofferVariants = (state) =>
  state.offerVariant.offerVariants || [];
export const selectIsLoading = (state) => state.offerVariant.loading;
export const selectIsUpateAPILoading = (state) =>
  state.offerVariant.waitingForUpdateApi;

export const {
  getofferVariantsSuccess,
  getofferVariantSuccess,
  setLoading,
  getofferVariantFailed,
  getofferVariantsFailed,
  setLoadingUpdate,
} = slice.actions;
export const setLoadingStatus = (loading) => (dispatch) => {
  dispatch(setLoading(loading));
};
export const fetchofferVariants = (id) => async (dispatch) => {
  try {
    setLoadingStatus(true);
    const offerVariants = await getOfferVariants(id);
    dispatch(getofferVariantsSuccess(offerVariants.data));
  } catch (err) {
    dispatch(getofferVariantsFailed(err.toString()));
  }
};

export const fetchofferVariant =
  (offerId, offerVariantId) => async (dispatch) => {
    try {
      const offerVariant = await getOfferVariant(offerId, offerVariantId);
      dispatch(getofferVariantSuccess(offerVariant.data));
    } catch (err) {
      dispatch(getofferVariantFailed(err.toString()));
    }
  };

export const updateofferVariantDetails =
  (offerId, offerVariantId, payload) => async (dispatch) => {
    try {
      dispatch(setLoadingUpdate(true));
      const offerVariant = await putOfferVariant(
        offerId,
        offerVariantId,
        payload
      );
      dispatch(setLoadingUpdate(false));
      dispatch(getofferVariantSuccess(offerVariant.data));
    } catch (err) {
      dispatch(getofferVariantFailed(err.toString()));
    }
  };

export default slice.reducer;
