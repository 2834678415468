import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "@reach/router";

import {
  selectUsers,
  fetchUsers,
  selectIsLoading,
  setLoadingStatus,
} from "./accountSlice";
import { Container, Spinner, Table, Button } from "react-bootstrap";

import styles from "./account.module.css";

const Account = () => {
  const users = useSelector(selectUsers);
  const isLoading = useSelector(selectIsLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <>
      <Container className={styles.createButtonContainer}>
        <Link className={styles.createLinkButton} to="/account-create">
          Create Account
        </Link>
      </Container>
      <Container
      //   className={styles.home}
      >
        <h3 className="text-center">Accounts</h3>
        {isLoading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : users.length > 0 ? (
          <Table className="text-center" responsive striped bordered hover>
            <thead>
              <tr>
                <th>SL No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Is Active</th>
                <th>Permission</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr
                  key={user.id}
                  // className={styles.model}
                >
                  <td>{index + 1}</td>
                  <td>{user.first_name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.is_active ? "Active" : "Not Active"}</td>
                  <td>
                    {user.user_type === "dealer_admin" ? "Dealer" : "Showroom"}
                  </td>
                  <th>
                    <Button
                      onClick={() => navigate(`/account/${user.id}`)}
                      variant="outline-primary"
                      size="sm"
                    >
                      View more
                    </Button>
                  </th>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No users found in your system.</p>
        )}
      </Container>
    </>
  );
};

export default Account;
