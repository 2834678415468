import { getApi, putApi } from "./utils";

export const getShowRoom = (id) => {
  return getApi(`showrooms/${id}`);
};

export const getShowRooms = () => {
  return getApi("showrooms");
};

export const updateShowRoom = (Id, updatedValues) => {
  return putApi(`showrooms/${Id}`, updatedValues);
};
