import { createSlice } from "@reduxjs/toolkit";
import { getShowRoom, getShowRooms, updateShowRoom } from "../../api/showRooms";

export const slice = createSlice({
  name: "showroom",
  initialState: {
    showrooms: [],
    showroom: {},
    loading: true,
    waitingForUpdateApi: false,
    error: {
      message: "",
    },
  },
  reducers: {
    getShowRoomsSuccess: (state, action) => {
      state.showrooms = action.payload;
      state.loading = false;
      state.error = {};
    },
    getShowRoomSuccess: (state, action) => {
      state.showroom = action.payload;
      state.loading = false;
      state.error = {};
    },

    getShowRoomsFailed: (state) => {
      state.showrooms = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    getShowRoomFailed: (state) => {
      state.showroom = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },

    setLoading: (state) => {
      state.loading = true;
    },
    setLoadingUpdate: (state, action) => {
      state.waitingForUpdateApi = action.payload;
    },
  },
});

export const selectShowRoom = (state) => state.showroom.showroom || [];
export const selectShowRooms = (state) => state.showroom.showrooms || [];
export const selectIsLoading = (state) => state.showroom.loading;
export const selectIsUpateAPILoading = (state) =>
  state.showroom.waitingForUpdateApi;

export const {
  getShowRoomsSuccess,
  getShowRoomSuccess,
  setLoading,
  getShowRoomFailed,
  getShowRoomsFailed,
  setLoadingUpdate,
} = slice.actions;
export const setLoadingStatus = (loading) => (dispatch) => {
  dispatch(setLoading(loading));
};
export const fetchShowRooms = () => async (dispatch) => {
  try {
    const showRooms = await getShowRooms();
    dispatch(getShowRoomsSuccess(showRooms.data));
  } catch (err) {
    dispatch(getShowRoomsFailed(err.toString()));
  }
};

export const fetchShowRoom = (id) => async (dispatch) => {
  try {
    const showRoom = await getShowRoom(id);
    dispatch(getShowRoomSuccess(showRoom.data));
  } catch (err) {
    dispatch(getShowRoomFailed(err.toString()));
  }
};

export const updateShowRoomDetails = (Id, payload) => async (dispatch) => {
  try {
    dispatch(setLoadingUpdate(true));
    await updateShowRoom(Id, payload);
    dispatch(setLoadingUpdate(false));
  } catch (err) {
    dispatch(getShowRoomFailed(err.toString()));
  }
};

export default slice.reducer;
