import { createSlice } from "@reduxjs/toolkit";
import {
  getFinanceLead,
  getFinanceLeads,
  updateFinanceLeadApi,
} from "../../api/financeLead";

export const slice = createSlice({
  name: "financeLead",
  initialState: {
    financeLeads: [],
    financeLead: {},
    loading: true,
    waitingForUpdateApi: false,
    waitingForPostApi: false,
    error: {
      message: "",
    },
  },
  reducers: {
    getFinanceLeadsSuccess: (state, action) => {
      state.financeLeads = action.payload;
      state.loading = false;
      state.error = {};
    },
    getFinanceLeadSuccess: (state, action) => {
      state.financeLead = action.payload;
      state.loading = false;
      state.error = {};
    },

    getFinanceLeadsFailed: (state) => {
      state.financeLeads = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    getFinanceLeadFailed: (state) => {
      state.financeLead = [];
      state.loading = false;
      state.error.message = "Something went wrong";
    },
    postFinanceLeadFailed: (state, action) => {
      state.financeLead = [];
      state.waitingForPostApi = false;
      state.error.message = action.payload;
    },

    setLoading: (state) => {
      state.loading = true;
    },
    resetError: (state) => {
      state.error = {};
    },
    setLoadingUpdate: (state, action) => {
      state.waitingForUpdateApi = action.payload;
    },

    setLoadingPost: (state, action) => {
      state.waitingForPostApi = action.payload;
    },
  },
});

export const selectfinanceLead = (state) => state.financeLead.financeLead || [];
export const selectfinanceLeads = (state) =>
  state.financeLead.financeLeads || [];
export const selectIsLoading = (state) => state.financeLead.loading;
export const selectIsUpateAPILoading = (state) =>
  state.financeLead.waitingForUpdateApi;
export const selectIsPostAPILoading = (state) =>
  state.financeLead.waitingForPostApi;
export const selectPostAPIError = (state) => state.financeLead.error;

export const {
  getFinanceLeadsSuccess,
  getFinanceLeadSuccess,
  setLoading,
  getFinanceLeadFailed,
  getFinanceLeadsFailed,
  postFinanceLeadFailed,
  resetError,
  setLoadingUpdate,
  setLoadingPost,
} = slice.actions;
export const setLoadingStatus = (loading) => (dispatch) => {
  dispatch(setLoading(loading));
};
export const fetchfinanceLeads = () => async (dispatch) => {
  try {
    setLoadingStatus(true);
    const financeLeads = await getFinanceLeads();
    dispatch(getFinanceLeadsSuccess(financeLeads.data));
  } catch (err) {
    dispatch(getFinanceLeadsFailed(err.toString()));
  }
};

export const fetchfinanceLead = (id) => async (dispatch) => {
  try {
    const financeLead = await getFinanceLead(id);
    dispatch(getFinanceLeadSuccess(financeLead.data));
  } catch (err) {
    dispatch(getFinanceLeadFailed(err.toString()));
  }
};

export const updatefinanceLead = (id, data) => async (dispatch) => {
  try {
    const financeLead = await updateFinanceLeadApi(id, data);
    dispatch(getFinanceLeadSuccess(financeLead.data));
  } catch (err) {
    dispatch(getFinanceLeadFailed(err.toString()));
  }
};

export default slice.reducer;
