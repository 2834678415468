import { createSlice } from "@reduxjs/toolkit";
import { getTestDrive } from "../../api/testdrive";

export const slice = createSlice({
  name: "testdrivedetail",
  initialState: {
    testdrive: [],
    loadingTestdrive: true,
    error: {
      message: "",
    },
  },
  reducers: {
    getTestDriveSuccess: (state, action) => {
      state.testdrive = action.payload;
      state.loadingTestdrive = false;
      state.error = {};
    },
    getTestDriveFailed: (state) => {
      state.testdrive = [];
      state.loadingTestdrive = false;
      state.error.message = "Something went wrong";
    },
    clearTestDrive: (state) => {
      state.testdrive = [];
      state.loadingTestdrive = true;
      state.error = {
        message: "",
      };
    },
  },
});

export const selectTestDrive = (state) => state.testdrivedetail.testdrive || [];
export const selectIsLoading = (state) =>
  state.testdrivedetail.loadingTestdrive;

export const { getTestDriveSuccess, getTestDriveFailed, clearTestDrive } =
  slice.actions;
export const fetchTestDrive = (id) => async (dispatch) => {
  try {
    const testDriveDetails = await getTestDrive(id);
    dispatch(getTestDriveSuccess(testDriveDetails.data));
  } catch (err) {
    dispatch(getTestDriveFailed(err.toString()));
  }
};

export default slice.reducer;
