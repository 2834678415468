export const ExchangeData = [
  {
    brand_name: "Maruti Suzuki",
    model_name: "Swift",
    fuel_type: "Diesel",
    make_year: "2014",
    km: "55000",
    customer_name: "Swaroop",
    ph_no: "9506123456",
    location: "Athani",
    email: "swaroopkp1@gmail.com",
    id: 1,
  },
  {
    brand_name: "Hyundai",
    model_name: "i10",
    fuel_type: "Petrol",
    make_year: "2015",
    km: "60000",
    customer_name: "Achu",
    ph_no: "8129654321",
    location: "mala",
    email: "achubaby95@gmail.com",
    id: 2,
  },
  {
    brand_name: "Mahindra",
    model_name: "XUV5OO",
    fuel_type: "Diesel",
    make_year: "2015",
    km: "65000",
    customer_name: "Arun",
    ph_no: "9946123456",
    location: "Edappally",
    email: " aruna12@gmail.com",
    id: 3,
  },
  {
    brand_name: "Toyota",
    model_name: "Etios Liva",
    fuel_type: "Petrol",
    make_year: "2016",
    km: "620000",
    customer_name: "Johnson",
    ph_no: "9744265545",
    location: "Kakkandu",
    email: "johnson212@gmail.com",
    id: 4,
  },
  {
    brand_name: "Volkswagen",
    model_name: "Polo",
    fuel_type: "Petrol",
    make_year: "2016",
    km: "75000",
    customer_name: "Ajmal",
    ph_no: "9544258147",
    location: "Aluva",
    email: " Ajmalaju23@gmail.com ",
    id: 5,
  },
];
