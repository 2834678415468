export const filterArray = (array, filters) => {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      // ignores non-function predicates
      if (!filters[key].length) {
        return true;
      }
      return filters[key].includes(item[key]);
    });
  });
};

export const filterForOffers = (array, filters) => {
  const filterKeys = Object.keys(filters);
  return array.filter((item) => {
    // validates all filter criteria
    return filterKeys.every((key) => {
      // ignores non-function predicates
      if (!filters[key].length) {
        return true;
      }
      return filters[key].includes(item["variant"][key]);
    });
  });
};
