import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "@reach/router";
import styles from "./Login.module.css";
import {
  verifyPasswordFromUser,
  selectError,
  selectUserData,
} from "./loginSlice";
import { Formik } from "formik";
import { validateEmailForm } from "./login_utils";
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(selectUserData);
  if (userData.token) {
    navigate("/");
  }
  const verifyPassword = (values = {}, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(verifyPasswordFromUser(values, setSubmitting, navigate));
  };
  const errorState = useSelector(selectError);

  const getOTPForm = () => (
    <Formik
      initialValues={{ email: "", password: "" }}
      validate={validateEmailForm}
      onSubmit={verifyPassword}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form
          className={styles.login}
          onSubmit={handleSubmit}
          autoComplete="new-password"
        >
          <Form.Group controlId="email">
            <Form.Label>Username:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              onChange={handleChange}
              value={values.email}
            />
            {errors.email && touched.email && errors.email}
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              autoComplete="new-password"
              placeholder="Enter your password"
              onChange={handleChange}
              value={values.password}
            />
            {errors.password && touched.password && errors.password}
          </Form.Group>
          {errorState.haveError ? errorState.message : ""}
          <Button
            className={styles.button_center}
            variant="primary"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Verifying credentials..." : "Login"}
          </Button>
        </Form>
      )}
    </Formik>
  );
  return <Container>{getOTPForm()}</Container>;
};
export default Login;
