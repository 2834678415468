import { getApi, putApi } from "./utils";

export const getFinanceLeads = () => {
  return getApi("finances");
};

export const getFinanceLead = (id) => {
  return getApi(`finances/${id}`);
};

export const updateFinanceLeadApi = (id, data) => {
  return putApi(`finances/${id}`, data);
};
