import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectofferVariant,
  fetchofferVariant,
  selectIsLoading,
  setLoadingStatus,
  updateofferVariantDetails,
  selectIsUpateAPILoading,
} from "./offerVariantSlice";
import { Container, Spinner, Row, Col, Button, Form } from "react-bootstrap";

const OfferVariant = ({ variantOfferId, offerId }) => {
  const offerVariant = useSelector(selectofferVariant);
  const isLoading = useSelector(selectIsLoading);
  const isUpateAPILoading = useSelector(selectIsUpateAPILoading);
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [editForm, setEditForm] = useState(false);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchofferVariant(offerId, variantOfferId));
  }, [dispatch, offerId, variantOfferId]);

  useEffect(() => {
    if (offerVariant) {
      setValue(offerVariant.value);
    }
  }, [offerVariant]);

  const handleClick = () => {
    if (editForm) {
      dispatch(updateofferVariantDetails(offerId, variantOfferId, { value }));
      setEditForm(false);
    } else {
      setEditForm(true);
    }
  };

  const cancelHandler = () => {
    setEditForm(false);
    dispatch(fetchofferVariant(offerId, variantOfferId));
  };

  return (
    <Container className="my-5">
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        offerVariant && (
          <>
            <h3 className="text-center">Edit Offer Variant</h3>
            <br />
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Variant :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    onChange={() => {}}
                    value={offerVariant.variant.name || ""}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Fuel Type :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    onChange={() => {}}
                    value={offerVariant.variant.fuel_type || ""}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Transmission Type :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    onChange={() => {}}
                    value={offerVariant.variant.transmission_type || ""}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Ex Showroom Starting :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    onChange={() => {}}
                    value={offerVariant.variant.ex_showroom_starting || ""}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Offer Amount :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    onChange={() => {}}
                    value={offerVariant.calculated_amount || ""}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Value :
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    className="font-weight-bold"
                    plaintext={!editForm}
                    readOnly={!editForm}
                    onChange={(e) => {
                      setValue(parseFloat(e.target.value));
                    }}
                    value={value || ""}
                  />
                </Col>
              </Form.Group>

              <Form.Group className="mt-5">
                <Button
                  variant="success"
                  size="sm"
                  onClick={handleClick}
                  disabled={isUpateAPILoading}
                  // disabled={isLoading}
                >
                  {isUpateAPILoading ? (
                    // {isLoading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : null}{" "}
                  {editForm ? "Save changes" : "Edit Values"}
                </Button>{" "}
                {editForm ? (
                  <Button variant="default" size="sm" onClick={cancelHandler}>
                    Cancel
                  </Button>
                ) : null}
              </Form.Group>
            </Form>
          </>
        )
      )}
    </Container>
  );
};

export default OfferVariant;

const FormControl = ({ edit, value, offerContent, onChange, as }) => {
  return (
    <Form.Control
      as={as}
      className="font-weight-bold"
      plaintext={!edit}
      readOnly={!edit}
      value={value || ""}
      onChange={(e) => onChange(e.target.value, offerContent)}
    />
  );
};
