import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectleads, selectIsLeadsLoading, fetchleads } from "./leadSlice";
import { Button, Container, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "@reach/router";

import moment from "moment";
import styles from "./Lead.module.css";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Leads = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedModel, setselectedModel] = useState("");
  const [models, setModels] = useState("");
  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const leads = useSelector(selectleads);
  const isLoading = useSelector(selectIsLeadsLoading);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchleads());
  }, [dispatch]);

  useEffect(() => {
    if (leads.length > 0) {
      setModels([...new Set(leads.map((testdrive) => testdrive.model.name))]);
      setStatus([...new Set(leads.map((testdrive) => testdrive.status))]);
      // include status
      const filteredVariants = filterData(
        leads,
        selectedModel,
        startDate,
        endDate,
        selectedStatus
      );
      setFilteredData(filteredVariants);
    }
  }, [leads, selectedModel, selectedStatus, startDate, endDate]);

  const exportToCSV = (apiData, fileName) => {
    const filteredData = apiData.map((item) => ({
      Showroom: item.showroom.place,
      Model: item.model.name,
      Variant: item.variant?.name,
      BookingDate: `${moment(item.created_at).format("DD-MMMM-YYYY")}`,
      Status: item.status,
      Customer: item.customer.first_name,
      ContactNo: item.customer.phone,
      Email: item.customer.email,
      Location: item.customer.current_location,
    }));

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leads");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    saveAs(new Blob([s2ab(excelBuffer)], { type: "" }), fileName + ".xlsx");
  };

  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const filterData = (data, modelName, startDate, endDate, selectedStatus) => {
    if (!modelName && !startDate && !endDate && !selectedStatus) {
      return data;
    }

    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    return data.filter((item) => {
      const isModelMatch = !modelName || item.model.name === modelName;

      const isStatusMatch = !selectedStatus || item.status === selectedStatus;

      if (!start && !end) {
        return isModelMatch && isStatusMatch;
      }

      const createdDate = new Date(item.created_at);

      const isDateMatch =
        (!start || createdDate >= start) && (!end || createdDate <= end);
      return isModelMatch && isDateMatch && isStatusMatch;
    });
  };

  const ModelSelect = () => {
    return (
      <select
        value={selectedModel}
        className="mx-2"
        onChange={(e) => setselectedModel(e.target.value)}
      >
        <option value={""}>All</option>
        {models &&
          models.map((result) => (
            <option key={result} value={result}>
              {result}
            </option>
          ))}
      </select>
    );
  };

  const StatusSelect = () => {
    return (
      <select
        value={selectedStatus}
        className="mx-2"
        onChange={(e) => setSelectedStatus(e.target.value)}
      >
        <option value={""}>All</option>
        {status &&
          status.map((result) => (
            <option key={result} value={result}>
              {result}
            </option>
          ))}
      </select>
    );
  };

  return (
    <Container
    //  className={styles.home}
    >
      <h3 className="text-center">Leads</h3>
      {leads.length > 0 && (
        <div className={styles.topSection}>
          <div
            className={styles.exportBtn}
            onClick={(e) =>
              exportToCSV(
                filteredData,
                `${moment(Date.now()).format("DD-MMMM-YYYY")}-${
                  selectedModel ? selectedModel : "All Models"
                }`
              )
            }
          >
            Download XL
          </div>
          <div className={styles.filter}>
            <div className={styles.datepick}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="dd-MMMM-yyyy"
                maxDate={new Date()}
                // showMonthYearPicker
                placeholderText="Select Start Date"
              />
            </div>
            <div className={styles.datepick}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="dd-MMMM-yyyy"
                maxDate={new Date()}
                // showMonthYearPicker
                placeholderText="Select End Date"
              />
            </div>
            <div
              className={styles.clearFilter}
              onClick={() => {
                setselectedModel("");
                setStartDate();
                setEndDate();
                setSelectedStatus("");
              }}
            >
              Clear Filter
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only text-center">Loading...</span>
        </Spinner>
      ) : filteredData.length > 0 ? (
        <Table className="text-center" responsive striped bordered hover>
          <thead>
            <tr>
              <th>No</th>
              <th>
                Model Name
                <ModelSelect />
              </th>
              <th>Variant</th>
              <th>Customer Name</th>
              <th>Customer No</th>
              <th>
                Status
                <StatusSelect />
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              .map((lead, index) => (
                <tr
                  onClick={() => navigate(`/lead/${lead.id}`)}
                  key={lead.id}
                  // className={styles.booking}
                >
                  <td>{index + 1}</td>
                  <td>{lead.model.name || lead.model_id}</td>
                  <td>{lead.variant?.name}</td>
                  <td>{lead.customer.first_name}</td>
                  <td>{lead.customer.phone}</td>
                  <td>{lead.status}</td>
                  <th>
                    <Button variant="outline-primary" size="sm">
                      View more
                    </Button>
                  </th>
                </tr>
              ))
              .reverse()}
          </tbody>
        </Table>
      ) : (
        <p>No Leads found</p>
      )}
    </Container>
  );
};

export default Leads;
