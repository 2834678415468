import React, { useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link, useNavigate } from "@reach/router";
// import Container from "react-bootstrap/Container";
import { selectUserData, logout } from "../login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import logo from "./logo.png";
import { Logout20 } from "@carbon/icons-react";
import styles from "./Header.module.css";
import get from "lodash/get";

const Header = () => {
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUserAuthenticated = get(userData, ["token"]);
  const onClick = () => {
    dispatch(logout());
    navigate("/login");
  };
  useEffect(() => {
    if (!isUserAuthenticated) {
      navigate("/login");
    }
  }, [isUserAuthenticated, navigate, userData]);

  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
      {/* <Container > */}
      <Navbar.Brand className={styles.brand}>
        <Link to="/" className={`navbar-brand  ${styles.brand}`}>
          <img src={logo} alt="logo" className={styles.img} />
          Carbae Dealer Dashboard
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="text-center">
        {isUserAuthenticated ? (
          <>
            <Nav className="mr-auto">
              <Navbar.Text>
                {userData.first_name} {userData.last_name} |{" "}
                {userData.dealer.name}
              </Navbar.Text>
            </Nav>
            <Nav className="mr-sm-2">
              {userData.user_type === "showroom_admin" && (
                <>
                  <Link to="/leads" className={`${styles.navbtn} nav-link`}>
                    Leads
                  </Link>
                  <Link to="/exchange" className={`${styles.navbtn} nav-link`}>
                    Exchange
                  </Link>

                  <Link
                    to="/financeLead"
                    className={`${styles.navbtn} nav-link`}
                  >
                    Finance Lead
                  </Link>
                </>
              )}
              <Link to="/testdrives" className={`${styles.navbtn} nav-link`}>
                Test Drives
              </Link>
              <Link to="/booking" className={`${styles.navbtn} nav-link`}>
                Bookings
              </Link>

              <Link to="/models" className={`${styles.navbtn} nav-link`}>
                Models
              </Link>
              {userData.user_type === "dealer_admin" && (
                <>
                  <Link to="/showroom" className={`${styles.navbtn} nav-link`}>
                    Showrooms
                  </Link>
                  <Link to="/accounts" className={`${styles.navbtn} nav-link`}>
                    Accounts
                  </Link>
                  <Link to="/finances" className={`${styles.navbtn} nav-link`}>
                    Finances
                  </Link>

                  <Link to="/offers" className={`${styles.navbtn} nav-link`}>
                    Offers
                  </Link>
                  <Link to="/offertype" className={`${styles.navbtn} nav-link`}>
                    Offer Type
                  </Link>
                </>
              )}

              <Button variant="primary" onClick={onClick}>
                <Logout20 />
              </Button>
            </Nav>
          </>
        ) : (
          // null
          <Nav className="ml-auto">
            <Link to="/login" className="nav-link">
              Login
            </Link>
          </Nav>
        )}
        <>
          {/* { !isUserAuthenticated ?
      <Nav className="mr-sm-2" >
          <Link to="/login" className="nav-link">
          Login
        </Link> 
      </Nav>
        :  <Nav className="mr-sm-2">
        <Button variant="primary" onClick={onClick}>
          <Logout20 />
        </Button>
        </Nav>} */}
        </>
      </Navbar.Collapse>

      {/* </Container> */}
    </Navbar>
  );
};
export default Header;
