import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Container from "react-bootstrap/Container";

import styles from "./Models.module.css";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import {
  fetchModel,
  fetchModelPriceDetails,
  selectActiveModel,
  selectActiveModelVariantPrice,
  selectIsLoading,
  selectIsUpateAPILoading,
  setLoadingStatus,
  updateModelPriceDetails,
} from "./modelsSlice";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import { useNavigate } from "@reach/router";
import { Table } from "react-bootstrap";

const VariantDetails = ({ modelId, variantId }) => {
  const model = useSelector(selectActiveModel);
  const variantOnRoadPrice = useSelector(selectActiveModelVariantPrice);
  const isLoading = useSelector(selectIsLoading);
  const isUpateAPILoading = useSelector(selectIsUpateAPILoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [priceDetails, setPriceDetails] = useState({});
  const [editForm, setEditForm] = useState(false);

  const [priceListPage, setPriceListPage] = useState(true);
  const [offerPage, setOfferPage] = useState(false);

  useEffect(() => {
    if (window.location.hash === "#offers") {
      setOfferPage(true);
      setPriceListPage(false);
      return;
    }
    if (window.location.hash === "#priceList") {
      setPriceListPage(true);
      setOfferPage(false);
      return;
    }
  }, []);

  const variantListHandler = () => {
    setPriceListPage(true);
    setOfferPage(false);
    navigate(`#priceList`);
  };

  const offerListHandler = () => {
    setPriceListPage(false);
    setOfferPage(true);
    navigate(`#offers`);
  };

  const variantName =
    model &&
    model?.variants?.find((variant) => variant.id === parseInt(variantId, 10))
      ?.name;
  useEffect(() => {
    dispatch(fetchModelPriceDetails(modelId, variantId));
    dispatch(fetchModel(modelId));
    dispatch(setLoadingStatus(true));
  }, [modelId, dispatch, variantId]);

  useEffect(() => {
    if (variantOnRoadPrice) {
      setPriceDetails(variantOnRoadPrice);
    }
  }, [variantOnRoadPrice]);

  const handleClick = () => {
    if (editForm) {
      dispatch(updateModelPriceDetails(modelId, variantId, priceDetails));
      setEditForm(false);
    } else {
      setEditForm(true);
    }
  };

  const onChange = (value, priceKey) => {
    const floatValue = value && parseFloat(value);
    setPriceDetails({
      ...priceDetails,
      [priceKey]: floatValue,
    });
  };
  const cancelHandler = () => {
    setEditForm(false);
    dispatch(fetchModelPriceDetails(modelId, variantId));
  };

  return (
    <div className={styles.container}>
      <div className={styles.sideTab}>
        <div className={styles.fixed}>
          <div
            className={`${styles.navItem} ${priceListPage && styles.active}`}
            onClick={variantListHandler}
          >
            PriceList
          </div>
          <div
            className={`${styles.navItem} ${offerPage && styles.active}`}
            onClick={offerListHandler}
          >
            Offers
          </div>
        </div>
      </div>
      <Container className={styles.booking}>
        {priceListPage && (
          <>
            <h3>{model.name ? `${model.name} Variant` : `Variant`}</h3>
            <h5>{variantName ? `Variant: ${variantName}` : null}</h5>
            <br />
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              priceDetails && (
                <Form>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      Ex-Showroom Price:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        className="font-weight-bold"
                        edit={editForm}
                        onChange={onChange}
                        value={priceDetails.ex_showroom_price}
                        priceKey="ex_showroom_price"
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      Min Booking amount:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        className="font-weight-bold"
                        edit={editForm}
                        onChange={onChange}
                        value={priceDetails.min_booking_amount}
                        priceKey="min_booking_amount"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      Insurance:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        edit={editForm}
                        onChange={onChange}
                        value={priceDetails.insurance_amount}
                        priceKey="insurance_amount"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      Road Tax:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        className="font-weight-bold"
                        edit={editForm}
                        onChange={onChange}
                        value={priceDetails.rto_amount}
                        priceKey="rto_amount"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      Registration Charges:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        className="font-weight-bold"
                        edit={editForm}
                        onChange={onChange}
                        value={priceDetails.registration_charges}
                        priceKey="registration_charges"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      Fastag:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        className="font-weight-bold"
                        edit={editForm}
                        onChange={onChange}
                        value={priceDetails.fast_tag}
                        priceKey="fast_tag"
                      />
                    </Col>
                  </Form.Group>
                  {
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column sm="3">
                        Extended warranty:
                      </Form.Label>
                      <Col sm="9">
                        <FormControl
                          className="font-weight-bold"
                          edit={editForm}
                          onChange={onChange}
                          value={priceDetails.extended_warranty}
                          priceKey="extended_warranty"
                        />
                      </Col>
                    </Form.Group>
                  }
                  {/* {priceDetails.basic_accessories_kit ? ( */}
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      Basic accessories kit:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        className="font-weight-bold"
                        edit={editForm}
                        onChange={onChange}
                        value={priceDetails.basic_accessories_kit}
                        priceKey="basic_accessories_kit"
                      />
                    </Col>
                  </Form.Group>
                  {/* ) : null} */}
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      Other Charges:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        edit={editForm}
                        value={priceDetails.other_charges}
                        priceKey="other_charges"
                        onChange={onChange}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      Discount:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        className="font-weight-bold"
                        edit={editForm}
                        onChange={onChange}
                        value={priceDetails.discount}
                        priceKey="discount"
                      />
                    </Col>
                  </Form.Group>
                  {priceDetails.tcs ? (
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column sm="3">
                        TCS:
                      </Form.Label>
                      <Col sm="9">
                        <FormControl
                          value={priceDetails.tcs}
                          plaintext
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  ) : null}
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      On Road Amount:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        className="font-weight-bold"
                        plaintext
                        readOnly
                        value={priceDetails.on_road_amount}
                      />
                    </Col>
                  </Form.Group>
                  {priceDetails.optional_charges ? (
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column sm="3">
                        Optional Charges:
                      </Form.Label>
                      <Col sm="9">
                        <FormControl
                          edit={editForm}
                          value={priceDetails.optional_charges}
                          priceKey="optional_charges"
                          onChange={onChange}
                        />
                      </Col>
                    </Form.Group>
                  ) : null}
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column sm="3">
                      On Road Amount with optional:
                    </Form.Label>
                    <Col sm="9">
                      <FormControl
                        className="font-weight-bold"
                        plaintext
                        readOnly
                        value={priceDetails.on_road_amount_with_optional}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group className={styles.buttons}>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleClick}
                      disabled={isUpateAPILoading}
                    >
                      {isUpateAPILoading ? (
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : null}{" "}
                      {editForm ? "Save changes" : "Change price breakup"}
                    </Button>{" "}
                    {editForm ? (
                      <Button
                        variant="default"
                        size="sm"
                        onClick={cancelHandler}
                      >
                        Cancel
                      </Button>
                    ) : null}
                  </Form.Group>
                </Form>
              )
            )}
          </>
        )}

        {offerPage && (
          <>
            <div className="my-3 d-grid gap-2 d-md-flex justify-content-md-end">
              <Button
                onClick={() => navigate(`/create-offer/Variant/${variantId}`)}
                variant="primary"
              >
                Create Offer
              </Button>
            </div>{" "}
            <h3 className="text-center">{model.name && `${model.name} `}</h3>
            <h5 className="text-center">
              {variantName && ` ${variantName} Active Offers`}
            </h5>
            <br />
            {/* <h3 className="text-center">Your Active Offers</h3> */}
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only text-center">Loading...</span>
              </Spinner>
            ) : priceDetails.offers ? (
              <>
                {priceDetails.offers.required && (
                  <>
                    <h5>Required Offers</h5>

                    <Table
                      className="text-center"
                      responsive
                      striped
                      bordered
                      hover
                    >
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Applicable Type</th>
                          <th>Offer Type</th>
                          <th>Status</th>
                          <th>Offer Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {priceDetails.offers?.required.map((offer, index) => (
                          <tr
                            onClick={() => navigate(`/offers/${offer.id}`)}
                            key={offer.id}
                            // className={styles.offer}
                          >
                            <td>{index + 1}</td>
                            <td>{offer.name}</td>
                            <td>{offer.applicable_type}</td>
                            <td>{offer.offer_type.name}</td>
                            <td>{offer.is_active ? "Active" : "Not Active"}</td>
                            <td>
                              {offer.amount}
                              {offer.valuation === "flat" ? " Rs " : " % "}
                            </td>

                            <th>
                              <Button variant="outline-primary" size="sm">
                                View more
                              </Button>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
                <br />
                {priceDetails.offers.optional && (
                  <>
                    <h5>Optional Offers</h5>

                    <Table
                      className="text-center"
                      responsive
                      striped
                      bordered
                      hover
                    >
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Applicable Type</th>
                          <th>Offer Type</th>
                          <th>Status</th>
                          <th>Offer Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {priceDetails.offers?.optional.map((offer, index) => (
                          <tr
                            onClick={() => navigate(`/offers/${offer.id}`)}
                            key={offer.id}
                            // className={styles.offer}
                          >
                            <td>{index + 1}</td>
                            <td>{offer.name}</td>
                            <td>{offer.applicable_type}</td>
                            <td>{offer.offer_type.name}</td>
                            <td>{offer.is_active ? "Active" : "Not Active"}</td>
                            <td>
                              {offer.amount}
                              {offer.valuation === "flat" ? " Rs " : " % "}
                            </td>

                            <th>
                              <Button variant="outline-primary" size="sm">
                                View more
                              </Button>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
                <br />
                {priceDetails.offers.additional && (
                  <>
                    <h5>Additional Offers</h5>

                    <Table
                      className="text-center"
                      responsive
                      striped
                      bordered
                      hover
                    >
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Name</th>
                          <th>Applicable Type</th>
                          <th>Offer Type</th>
                          <th>Status</th>
                          <th>Offer Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {priceDetails.offers?.additional.map((offer, index) => (
                          <tr
                            onClick={() => navigate(`/offers/${offer.id}`)}
                            key={offer.id}
                            // className={styles.offer}
                          >
                            <td>{index + 1}</td>
                            <td>{offer.name}</td>
                            <td>{offer.applicable_type}</td>
                            <td>{offer.offer_type.name}</td>
                            <td>{offer.is_active ? "Active" : "Not Active"}</td>
                            <td>
                              {offer.amount}
                              {offer.valuation === "flat" ? " Rs " : " % "}
                            </td>

                            <th>
                              <Button variant="outline-primary" size="sm">
                                View more
                              </Button>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                )}
                <br />
              </>
            ) : (
              <p>No offers found</p>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

const FormControl = ({ edit, value, priceKey, onChange }) => {
  return (
    <Form.Control
      className="font-weight-bold"
      plaintext={!edit}
      readOnly={!edit}
      value={value || ""}
      onChange={(e) => onChange(e.target.value, priceKey)}
    />
  );
};
export default VariantDetails;
