import React, { useState } from "react";
import VariantList from "../../components/Model/VaraintList";
import OfferList from "../../components/Model/OfferList";
import { Container } from "react-bootstrap";
import styles from "./Models.module.css";
import { useNavigate } from "@reach/router";
import { useEffect } from "react";
const ModelDetail = ({ modelId = "" }) => {
  const navigate = useNavigate();

  const [variantPage, setVariantPage] = useState(true);
  const [offerPage, setOfferPage] = useState(false);

  useEffect(() => {
    if (window.location.hash === "#offers") {
      setOfferPage(true);
      setVariantPage(false);
      return;
    }
    if (window.location.hash === "#variants") {
      setVariantPage(true);
      setOfferPage(false);
      return;
    }
  }, []);

  const variantListHandler = () => {
    setVariantPage(true);
    setOfferPage(false);
    navigate(`#variants`);
  };

  const offerListHandler = () => {
    setVariantPage(false);
    setOfferPage(true);
    navigate(`#offers`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.sideTab}>
        <div className={styles.fixed}>
          <div
            className={`${styles.navItem} ${variantPage && styles.active}`}
            onClick={variantListHandler}
          >
            Varaints
          </div>
          <div
            className={`${styles.navItem} ${offerPage && styles.active}`}
            onClick={offerListHandler}
          >
            Offers
          </div>
        </div>
      </div>
      <Container>
        {variantPage && <VariantList id="variants" modelId={modelId} />}
        {offerPage && <OfferList id="offers" modelId={modelId} />}
      </Container>
    </div>
  );
};

export default ModelDetail;
