import React from "react";
import Container from "react-bootstrap/Container";
import style from "./term.module.css";
const Terms = () => (
  <Container className={style.term}>
    <br />
    <h1 className="text-center">Terms and conditions</h1>
    <br />

    <p className={style.font}>
      The CarBae Terms and conditions ( Terms of services) is a legally binding
      agreement between you (“you” and “your”) and CarBae ie Autobae
      Technologies private limited (hereinafter referred individually and/ or
      collectively to as “CarBae” “Carbae” “we” “our”) .By using our website you
      are indicating that you have read and accepted the Terms and conditions
      and privacy policy{" "}
    </p>
    <p>
      THESE WEBSITE TERMS OF USE ("Terms Of Use" or “ Terms and Conditions”) IS
      AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER
      INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER AND THE AMENDED
      PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS
      STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THESE TERMS
      OF USE DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
    </p>

    <p className={`font-weight-bold ${style.font}`}>
      Carbae shall not be responsible for any service availed by You from third
      parties such as dealerships ,OEMS or financial institutions ,channel
      partners or any other third parties or any payment made by You to such
      third parties in connection with the services. Any concern or claims in
      relation to such services should be directed by You to such third parties
    </p>

    <h2>1. Membership Eligibility</h2>
    <p>
      Full use of the Site is available only to persons who can form legally
      binding contracts with Carbae under an applicable law. Persons who are
      "incompetent to contract" within the meaning of the Indian Contract Act,
      1872 including minors, un-discharged insolvents etc. are not eligible to
      use the Site. The Site is not available to persons whose membership has
      been suspended or terminated by Carbae. If you are registering as a
      business entity, you represent that you have the authority to bind the
      entity to this User Agreement
    </p>

    <div>
      <h2>2. Your Account and Registration Obligations</h2>
      <p>
        If you use the Site, you are responsible for maintaining the
        confidentiality of your account like email ID , Password, etc. and for
        restricting access to your computer, and you agree to accept
        responsibility for all activities that occur under your account or
        Password. You agree to:
      </p>
      <p>
        (a) Provide true, accurate, current and complete information about
        yourself as prompted by registration form available on the Site (such
        information being the "Registration Data"); and
      </p>
      <p>
        (b) Maintain and promptly update the Registration Data to keep it true,
        accurate, current and complete. If you provide any information that is
        untrue, inaccurate, not current or incomplete or Carbae has a reasonable
        ground to suspect that such information is untrue, inaccurate, not
        current or incomplete, or not in accordance with the User Agreement,
        Carbae has the right to indefinitely suspend or terminate your
        membership and refuse to provide you with access to the Site.
      </p>
    </div>

    <div>
      <h2>Important : </h2>
      <p>
        This Website, including mobile application, is an online marketplace and
        an Intermediary in terms of Information Technology Act, 2000 (as amended
        from time to time), which provides a platform to users to avail the
        Services. Any information provided either through or at Website is only
        for information purpose and such information does not substitute any
        specific advice whether investment, legal, taxation or otherwise and are
        not intended to provide You with any nature of certification, guarantee
        or warranty. Carbae is not responsible and can not be held liable for
        any transaction between the Website's User.
      </p>
      <p>
        By accessing, browsing and using this Website or availing Services, You
        agree and acknowledge that, You understand this limited and restricted
        use and accessing, browsing or using this Website or availing any
        Services is out of your own free will, discretion and responsibility.
      </p>
      <p>
        Carbae reserves its right to modify, suspend, cancel or discontinue any
        or all sections, or Services at any time without any notice. Carbae
        reserves the right to make modifications and alterations in the
        information contained on the Website without notice. You agree that
        Carbae shall not be liable to You or to any third party for any
        modification, suspension or discontinuance of the Website.
      </p>
      <p>
        You expressly agree that any information provided on the Website shall
        be used by you at your risk. You are advised to hereby make proper
        enquiries and use the information provided on the Website.
      </p>
      <p>
        From time to time Carbae may supplement its Terms of Use with additional
        terms pertaining to specific content and Your usage of mobile app may
        further require you to abide by the additional terms of third party e.g.
        Play Store etc. before download and installing the mobile app. These
        terms are collectively referred as ("Additional Terms"). Such Additional
        Terms are hereby incorporated by reference into these Terms of Use.
      </p>
    </div>

    <div>
      <h2>Features And Content:</h2>
      <p>
        “Contents" or "Content" shall mean any and all information, data, text,
        software, music, sound, photographs, graphics, video, messages,
        materials, news, notices, articles, contracts, forms, documents or other
        materials and information which may be viewed or downloaded on or
        through this Website. Contents shall also include any e-mail, messages,
        e-cards or any other information furnished by a user to be displayed on
        the Website by Carbae.The content or contents may subject to change
        without prior notice. Prices prevailing at the time of delivery of the
        vehicle will be applicable
      </p>
      <p>
        "Feature" or "Features": A "Feature" may include any interactive, value
        addition service or other additional feature including without
        limitation comparison,. which are introduced or available on the
        Website.
      </p>
      <p>
        Carbae may allow the User of Website to upload or post certain content,
        data or information, message, files, photographs, pictures, articles,
        feedback or any other material of combination thereof (collectively
        referred to "User Content") accessible to others or transmit
        communications to others. Some of these tools may be operated by third
        parties, and Your use of them is subject to both the terms of this
        Agreement and to the policies of their third-party providers. This
        Feature is available to You as a matter of convenience only and to
        express Your views on products / services available through or at
        Website.
      </p>
      <p>
        Being the originator of the User Content, You are solely responsible for
        the User Content that You upload, post, publish, transmit or otherwise
        make available on the Website. You represent that you have obtained all
        relevant consents and approvals in order to post any User Content. You
        further represent that all such User Content will be in accordance with
        applicable law. You acknowledges and agree that of the User Content
        posted or transmitted through our Website represents the views of the
        author, and not of Cardekho.com. You also acknowledge that your use of
        or reliance on such content is at your own risk.
      </p>
      <p>
        You acknowledge that Carbae does not endorse any User Content on the
        Website and is not responsible or liable for any User Content. In case
        the User Content is in violates/infringes any patent, trademark, trade
        secret, copyright or any other proprietary or privacy rights of any
        third party or in contravention of any applicable law, then Girnar at
        its sole discretion may remove or disable the access to the User Content
        or any part thereof, without any notice to User.
      </p>
      <p>
        Assignment of User Content: You hereby grant Carbae a perpetual,
        non-revocable, worldwide, royalty-free and sub-licensable right and
        license to use, copy, distribute, display, publish, transmit, make
        available, reproduce, modify, adapt the User Content and create derivate
        works of the User Content. You represent and warrant that You own or
        otherwise control all of the rights to the User Content that You post or
        that You otherwise provide on or through the Website; and that, as at
        the date that the User Content is posted or submitted on the Website:
        (i) the User Content is accurate; (ii) use of the User Content You
        supply does not breach these Terms of Use; and (iii) that such User
        Content is lawful.
      </p>
    </div>

    <div>
      <h2>User’s Obligation:</h2>
      <ul>
        <li>
          You shall not use or access the Website or avail the Services by any
          means other than through the interface that are provided by Carbae
        </li>
        <li>
          When You use the Website and/or the Services You specifically
          undertake not to host, display, upload, modify, publish, transmit,
          update or share any
        </li>
        <ol>
          <li>
            is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, libellous, invasive of another's privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatsoever;
          </li>
          <li>
            infringes any patent, trademark, copyright or other proprietary
            rights
          </li>
          <li>
            contain software viruses or any other computer code, files or
            programs designed to interrupt destroy or limit the functionality of
            any computer resource;
          </li>
          <li>
            Reverse-engineer or attempt to steal the software code of the site
          </li>
        </ol>
        <br />
        <h3>Fees and payments</h3>
        <p>
          When You make the payment through third party payment gateway, such
          third party process Your Payment to Carbae, provided You comply with
          all applicable rules and regulations. Carbae shall not be responsible
          for any failure/decline in making payment due to any reason (including
          without limitation exceeding limit, wrong details etc.) as Carbae does
          not control such third parties.
        </p>
        <h3>Disclaimer of warranty and liability</h3>
        <p>
          WHEN YOU ACCESS THE WEBSITE OR AVAIL ANY PRODUCT OR SERVICES AT OR
          THROUG WEBSITE, YOU DO SO AT YOUR OWN RISK. YOU UNDERSTAND THAT THERE
          MAY BE DELAYS, OMISSIONS, INTERRUPTIONS, INACCURACIES, AND/OR OTHER
          PROBLEMS WITH THE INFORMATION, PRODUCTS, AND SERVICES PUBLISHED ON OR
          PROMOTED THROUGH THIS WEBSITE. WITHOUT LIMITING THE FOREGOING, CARBAE
          AND ITS AFFILIATES, AGENTS, AND LICENSORS MAKES NO WARRANTY THAT
        </p>
      </ul>
    </div>

    <h2>Indemnification and Limitation of Liability:</h2>
    <p>
      <u>Indemnity</u>: You agree to indemnify, defend and hold harmless Carbae,
      its subsidiaries, affiliates, vendors, agents and their respective
      directors, officers, employees, contractors and agents (herein after
      individually and collectively referred to as
      <span className="font-weight-bold"> "Indemnified Parties"</span>) from and
      against any and all losses, liabilities, claims, suits, proceedings,
      penalties, interests, damages, demands, costs and expenses (including
      legal and other statutory fees and disbursements in connection therewith
      and interest chargeable thereon) asserted against or incurred by the
      Indemnified Parties that arise out of, result from, or in connection with
      (i) Your breach of the Agreement(s); or (ii) any claims made by any third
      party due to, or arising out of, or in connection with, Your use of the
      Website; or (iii) any claim that any Content / User Content / Third Party
      Content, information or materials provided by You caused damage to a third
      party; or (iv) Your violation of any rights of another, including any
      intellectual property rights.
    </p>

    <h2>Termination </h2>
    <p>
      You are free to stop our services any time however we also reserve the
      right to suspend or end the service at any time at our discretion without
      notice
    </p>

    <h2>Modifications</h2>
    <p>
      We’ll revise these Terms of conditions from time to time to better to
      reflect to changes to the law , new regulatory requirement or improvements
      or enhancements made to our product or services and you will be notified
      prior to the updates in case any updates are made
    </p>

    <h2>Contact information </h2>
    <div>
      <a href="mailto:contact@carbae.in" className="text-secondary">
        contact@carbae.in
      </a>
    </div>
    <br />
  </Container>
);

export default Terms;
