import { deleteApi, getApi, postApi, putApi } from "./utils";

export const getUsers = () => {
  return getApi("users");
};

export const getUser = (id) => {
  return getApi(`users/${id}`);
};

export const createUser = (data) => {
  return postApi("users", data);
};

export const updateUser = (id, data) => {
  return putApi(`users/${id}`, data);
};

export const deleteUser = (id) => {
  return deleteApi(`users/${id}`);
};
