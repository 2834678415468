import { createSlice } from "@reduxjs/toolkit";
import { clear } from "redux-localstorage-simple";
import { verifyPassword } from "../../api/user";
import { resetUserSession } from "../../api/utils";
import { clearModels } from "../models/modelsSlice";
import { clearBooking } from "../booking/bookingSlice";
import { clearBookings } from "../home/homeSlice";

export const slice = createSlice({
  name: "user",
  initialState: {
    data: {},
    error: {
      message: "",
      haveError: false,
    },
  },
  reducers: {
    AuthVerifySuccess: (state, action) => {
      state.data = action.payload;
      state.error.haveError = false;
    },
    AuthVerifyFailed: (state, action) => {
      state.data = {};
      state.error = {
        message: action.payload,
        haveError: true,
      };
    },
    AuthLogout: (state) => {
      state.data = {};
      state.error = {};
    },
  },
});

export const selectUserData = (state) => state.user.data || {};
export const selectError = (state) => state.user.error || {};
export const {
  loginApiLoading,
  OTPSendSuccess,
  OTPSendFailed,
  AuthVerifySuccess,
  AuthVerifyFailed,
  AuthLogout,
} = slice.actions;

export const verifyPasswordFromUser =
  ({ email = "", password = "" }, setSubmitting, navigate) =>
  async (dispatch) => {
    try {
      const loginSuccessDetails = await verifyPassword({ email, password });
      if (loginSuccessDetails.status === "error") {
        dispatch(AuthVerifyFailed(loginSuccessDetails.message));
        setSubmitting(false);
      } else {
        dispatch(AuthVerifySuccess(loginSuccessDetails.data.user));
        setSubmitting(false);
        navigate("/");
      }
    } catch (err) {
      dispatch(AuthVerifyFailed(err.toString()));
      setSubmitting(false);
    }
  };

export const logout = () => async (dispatch) => {
  clear({ namespace: "carbaeDealer" });
  resetUserSession();
  dispatch(AuthLogout());
  dispatch(clearModels());
  dispatch(clearBooking());
  dispatch(clearBookings());
};

export default slice.reducer;
