import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  fetchUser,
  selectIsLoading,
  setLoadingStatus,
  selectIsUpateAPILoading,
  updateUserDetails,
  deleteUserDetails,
} from "./accountSlice";

import {
  Container,
  Spinner,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "@reach/router";

const AccountDetails = ({ accountId = "" }) => {
  const user = useSelector(selectUser);
  const isLoading = useSelector(selectIsLoading);
  const isUpateAPILoading = useSelector(selectIsUpateAPILoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({});
  const [editForm, setEditForm] = useState(false);
  const [show, setShow] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(fetchUser(accountId));
  }, [dispatch, accountId]);

  useEffect(() => {
    if (user) {
      setUserDetails(user);
    }
  }, [user]);

  const handleClick = () => {
    if (editForm) {
      dispatch(updateUserDetails(accountId, userDetails));
      setEditForm(false);
    } else {
      setEditForm(true);
    }
  };

  const updatePasswordHandler = () => {
    if (editPassword) {
      if (
        password !== confirm_password ||
        password.length < 6 ||
        confirm_password.length < 6
      ) {
        setShow(true);
        return;
      }
      const payload = {
        id: accountId,
        password: password,
        password_confirmation: confirm_password,
      };
      dispatch(updateUserDetails(accountId, payload));
      setEditPassword(false);
    } else {
      setEditPassword(true);
    }
  };

  const onChange = (value, userContent) => {
    setUserDetails({
      ...userDetails,
      [userContent]: value,
    });
  };
  const cancelHandler = () => {
    setEditForm(false);
    setEditPassword(false);
    dispatch(fetchUser(accountId));
  };
  const deleteHandler = (id) => {
    dispatch(deleteUserDetails(id, navigate));
  };
  return (
    <Container>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        userDetails && (
          <>
            <h3 className="text-center">Account Details</h3>
            <br />
            <Form>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Email :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={userDetails.email}
                    userContent="email"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  First Name :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={userDetails.first_name}
                    userContent="first_name"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Last Name :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={userDetails.last_name}
                    userContent="last_name"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Phone :
                </Form.Label>
                <Col sm="9">
                  <FormControl
                    className="font-weight-bold"
                    edit={editForm}
                    onChange={onChange}
                    value={userDetails.phone}
                    userContent="phone"
                  />
                </Col>
              </Form.Group>
              {!editForm ? (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    Is Active
                  </Form.Label>
                  <Col sm="9">
                    <FormControl
                      value={userDetails.is_active ? "Yes" : "No"}
                      className="font-weight-bold"
                    />
                  </Col>
                </Form.Group>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column sm="3"></Form.Label>
                  <Col sm="9">
                    <Form.Check
                      label="Is Active"
                      id="disabled-custom-switch"
                      checked={userDetails.is_active}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          is_active: e.target.checked,
                        })
                      }
                    />
                  </Col>
                </Form.Group>
              )}
              {!editForm ? (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    User Type
                  </Form.Label>
                  <Col sm="9">
                    <FormControl
                      value={
                        userDetails.user_type === "dealer_admin"
                          ? "Dealer"
                          : "Showroom"
                      }
                      className="font-weight-bold"
                    />
                  </Col>
                </Form.Group>
              ) : (
                <Form.Group as={Row}>
                  <Form.Label column sm="3">
                    User Type :
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="select"
                      value={userDetails.user_type}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          user_type: e.target.value,
                        })
                      }
                    >
                      <option disabled value="">
                        Select User Type
                      </option>
                      {/* <option value="dealer_admin">Dealer Admin </option> */}
                      <option value="showroom_admin">Showroom Admin</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              )}
              {editPassword && (
                <>
                  {" "}
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Password :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="password"
                        className="font-weight-bold"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm="3">
                      Confirm Password :
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="password"
                        className="font-weight-bold"
                        placeholder="Confirm Password"
                        value={confirm_password}
                        onChange={(e) => setConfirm_password(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </>
              )}
              {show && (
                <Alert
                  variant="danger"
                  onClose={() => setShow(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>Please Check The Password Again</p>
                </Alert>
              )}{" "}
              <Form.Group>
                {!editPassword && (
                  <Button
                    variant="success"
                    size="sm"
                    onClick={handleClick}
                    disabled={isUpateAPILoading}
                  >
                    {isUpateAPILoading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : null}{" "}
                    {editForm ? "Save changes" : "Edit Values"}
                  </Button>
                )}{" "}
                {!editForm && (
                  <Button
                    variant="success"
                    size="sm"
                    onClick={updatePasswordHandler}
                    disabled={isUpateAPILoading}
                  >
                    {isUpateAPILoading ? (
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : null}{" "}
                    {editPassword ? "Save changes" : "Edit Password"}
                  </Button>
                )}{" "}
                {editForm || editPassword ? (
                  <Button variant="default" size="sm" onClick={cancelHandler}>
                    Cancel
                  </Button>
                ) : null}
                {!editForm && !editPassword ? (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => deleteHandler(userDetails.id)}
                    disabled={isUpateAPILoading}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </Form.Group>
            </Form>
          </>
        )
      )}
    </Container>
  );
};

export default AccountDetails;

const FormControl = ({ edit, value, userContent, onChange }) => {
  return (
    <Form.Control
      className="font-weight-bold"
      plaintext={!edit}
      readOnly={!edit}
      value={value || ""}
      onChange={(e) => onChange(e.target.value, userContent)}
    />
  );
};
