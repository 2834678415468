import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchfinanceLead,
  selectIsLoading,
  selectfinanceLead,
  updatefinanceLead,
} from "./financeLeadSlice";
import styles from "../lead/Lead.module.css";
import { Badge, Button, Spinner } from "react-bootstrap";
import moment from "moment";
import Edit from "../lead/edit.svg";
import { FinanceStatus } from "../data";
const FinanceLeadDeatils = ({ financeLeadId }) => {
  const dispatch = useDispatch();

  const financeLeadss = useSelector(selectfinanceLead);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    dispatch(fetchfinanceLead(financeLeadId));
  }, [financeLeadId, dispatch]);

  const [edit, setEdit] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const minor_color = `mb-2 ${styles.text_color}`;

  const EditVaraint = () => {
    setEdit(true);
  };

  const selectHandler = (value) => {
    setSelectedValue(value);
  };

  const submitHandler = () => {
    if (selectedValue) {
      dispatch(
        updatefinanceLead(financeLeadss.id, { status: selectedValue.value })
      );
      setEdit(false);
      setSelectedValue(null);
    }
  };

  return (
    <div className={styles.booking}>
      <h3 className={styles.heading}>Finance Lead details</h3>
      {isLoading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        financeLeadss && (
          <div className={styles.container}>
            <div className={styles.bookingCard}>
              <div className={`${styles.box} ${styles.center}`}>
                <div>
                  {/* <h1>Customer Details</h1> */}
                  <p className={minor_color}>
                    Name:{" "}
                    <strong>
                      {financeLeadss?.customer?.first_name}{" "}
                      {financeLeadss?.customer?.last_name}
                    </strong>
                  </p>
                  <p className={minor_color}>
                    Phone: <strong>{financeLeadss.customer?.phone} </strong>
                  </p>
                  <p className={minor_color}>
                    Email: <strong>{financeLeadss.customer?.email} </strong>
                  </p>
                  {financeLeadss?.customer?.current_location && (
                    <div className={`${minor_color} d-flex`}>
                      <p>Location: </p>
                      <div>
                        <strong>
                          {financeLeadss.customer.current_location}
                        </strong>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.box}>
                <h2 className={styles.heading}>{financeLeadss?.model?.name}</h2>

                <div className={styles.textcenter}>
                  {financeLeadss.variant && (
                    <p className={minor_color}>
                      {financeLeadss.variant?.name} -{" "}
                      {financeLeadss.variant?.transmission_type}
                    </p>
                  )}

                  <p className={minor_color}>
                    <Badge variant="primary p-3">
                      {
                        FinanceStatus.find(
                          (status) => status.value === financeLeadss.status
                        )?.name
                      }
                    </Badge>
                    <img
                      onClick={EditVaraint}
                      className={styles.editIcon}
                      src={Edit}
                      alt="editIcon"
                    />
                  </p>
                </div>
              </div>
              <div className={`${styles.box} ${styles.center}`}>
                {financeLeadss.showroom ? (
                  <p className={minor_color}>
                    Showroom : <strong> {financeLeadss.showroom.place}</strong>
                  </p>
                ) : null}
                {financeLeadss.down_payment && (
                  <p className={minor_color}>
                    Down Payment : <strong>{financeLeadss.down_payment}</strong>
                  </p>
                )}
                {financeLeadss.emi_approx && (
                  <p className={minor_color}>
                    EMI Approx : <strong>{financeLeadss.emi_approx}</strong>
                  </p>
                )}
                {financeLeadss.loan_amount && (
                  <p className={minor_color}>
                    Loan Amount : <strong>{financeLeadss.loan_amount}</strong>
                  </p>
                )}
                {financeLeadss.tenure && (
                  <p className={minor_color}>
                    Tenure : <strong>{financeLeadss.tenure}</strong>
                  </p>
                )}
                {financeLeadss.created_at ? (
                  <p className={minor_color}>
                    Created on :{" "}
                    <strong>
                      {moment(new Date(financeLeadss.created_at)).format(
                        " h:mm a , MMM-D-YYYY"
                      )}
                    </strong>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        )
      )}
      {edit && (
        <div className={styles.modal}>
          <span
            onClick={() => {
              setEdit(false);
              setSelectedValue(null);
            }}
            className={styles.btn}
          >
            &times;
          </span>
          <div className={styles.content}>
            <div className={styles.editItems}>
              {FinanceStatus.map((stat) => (
                <p
                  className={
                    stat.value === financeLeadss.status
                      ? `${styles.items} ${styles.selected}`
                      : stat.value === selectedValue?.value
                      ? `${styles.items} ${styles.newSelected}`
                      : styles.items
                  }
                  key={stat.id}
                  onClick={() => selectHandler(stat)}
                >
                  {stat.name}
                </p>
              ))}
            </div>
            <div className="col text-center ">
              <Button
                onClick={submitHandler}
                variant="primary"
                disabled={!selectedValue}
                className={styles.button}
              >
                Change
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FinanceLeadDeatils;
